/** @jsxImportSource @emotion/react */
import { faBarcodeRead } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
    Button,
    Modal, ModalBody, ModalFooter, ModalHeader
} from "reactstrap";
import { addLayerLocationHistory } from "../../../../actions/Layers/actions";
import { LAYER_STAGE, LAYER_TYPE } from "../../../../actions/Layers/constants";
import LayerAutoComplete from "../../../Forms/LayerAutoComplete";
import { RadioButtons } from "../../../Forms/RadioButton";

import useConfig from "../../../../actions/Tenants/config/configHook";
import { toast } from "../../../../utils/toast";
import MetaForm from "../../../Forms/MetaForm";
import QRCodeScanner from "../../../Forms/QRCodeScanner";
import { searchLayers } from "../../../Forms/utils";
import { isItemAllocatedOnDateHook } from "./hooks";


interface LayerItem {
    id?: string;
    type?: string;
    label?: string;
    fruit_type?: string;
}

// * Add pallets to this cell (pallets from outside the cell)
export function AddSinglePalletModal({ setModal, modalOpen, refreshAllocatedLayers }: { setModal: any, modalOpen: boolean, refreshAllocatedLayers: any }) {
    const [layer, setLayer] = useState<any>(null);
    const dispatch = useDispatch();
    const config = useConfig();
    const params = useParams();
    const { location_id } = params;

    const handleScan = (result: string) => {
        const filteredResult = config.barcode_filter(result);
        /**
         * Scan the barcode and search for pallet with that label
         */
        const searchResult = (data: LayerItem[]) => {
            if (data.length === 1) {
                setLayer(data[0]);
            } else if (data.length > 1) {
                toast.info(`Multiple pallets found with label ${result}`);
            } else {
                // if not exsite we create a new layer
                toast.info("Pallet not found");
            }
        };


        const handleError = (error: Error) => {
            toast.error(error.message);
        };
        searchLayers(filteredResult, LAYER_TYPE.PALLET)
            .then(searchResult)
            .catch(handleError);

    };

    const addPallet = () => {

        if (!layer?.id) {
            return;
        }
        dispatch(addLayerLocationHistory({ layer_id: layer.id, stage: LAYER_STAGE.RIPENING, location_id }) as any).then(() => {
            refreshAllocatedLayers();
            setModal(false);
        });
    };
    return <Modal isOpen={modalOpen} size={"md"} toggle={() => setModal(false)}>
        <ModalHeader toggle={() => setModal(false)} >Add pallets</ModalHeader>
        <ModalBody>
            <div className="pb-2">
                <QRCodeScanner onScan={handleScan} />
            </div>
            <div>
                <LayerAutoComplete creatable={false} type={LAYER_TYPE.PALLET} setLayer={setLayer} layer={layer} />
            </div>
        </ModalBody>
        <ModalFooter>
            <Button size="sm" color="light" onClick={() => setModal(false)}>Cancel</Button>
            <Button size="sm" color="primary" onClick={addPallet}>Add pallet</Button>
        </ModalFooter>
    </Modal>;
}

// * Move pallets out of this cell based on the selected
export function RemoveLayerModal({ setModal, modalOpen, selectedLayers, refreshAllocatedLayers, deselectAll }: { setModal: any, modalOpen: boolean, selectedLayers: string[], refreshAllocatedLayers: any, deselectAll: any }) {
    const locations = useSelector((state:any) => state.facilityLocations.locations);
    const currentLocation = useSelector((state:any) => state.facilityLocations.current);
    const [new_location_id, setNewLocationId] = useState(null);

    // * use value instead of location_id for locations so that the RadioButtons component can use it
    const transformedLocations = locations.filter((i) => i.location_id !== currentLocation.location_id).map((cell) => ({
        ...cell,
        value: cell.location_id
    }));


    const dispatch = useDispatch();

    const allocateLayerToLocation = useCallback(() => {
        const location = locations.find((cell) => cell.location_id === new_location_id);

        if (!location) {
            return;
        }

        const { stage, location_id } = location;

        Promise.all(selectedLayers.map((layer_id) => dispatch(addLayerLocationHistory({ layer_id, stage, location_id, include_children: false }) as any))).then(() => {
            refreshAllocatedLayers();
            deselectAll();
            setModal(false);
        });

    }, [new_location_id]);

    return <Modal isOpen={modalOpen} size="md">
        <ModalHeader >Move {selectedLayers.length} pallets</ModalHeader>
        <ModalBody>
            <RadioButtons
                color="secondary"
                name="location"
                current={new_location_id || ""}
                options={transformedLocations}
                onChange={(_, value) => setNewLocationId(value)}></RadioButtons>
        </ModalBody>
        <ModalFooter>
            <Button color="primary" disabled={!new_location_id} onClick={() => allocateLayerToLocation() }>Allocate</Button>
            <Button color="light" onClick={() => setModal(false)}>Cancel</Button>
        </ModalFooter>
    </Modal>;

}

export function ScanToMoveLayerModal({ refreshAllocatedLayers, pallets }: {refreshAllocatedLayers: any, pallets: any }) {
    const locations = useSelector((state:any) => state.facilityLocations.locations);
    const currentLocation = useSelector((state:any) => state.facilityLocations.current);
    const [modalOpen, setModal] = useState(false);
    const [query, setQuery] = useState<any>({});


    const isItemAllocatedOnDate = isItemAllocatedOnDateHook();
    const today = dayjs();
    const activePallets = pallets.filter((p) => isItemAllocatedOnDate(p.id, today));

    const config = useConfig();

    // * use value instead of location_id for locations so that the RadioButtons component can use it
    const transformedLocations = locations.filter((i) => i.location_id !== currentLocation.location_id).map((cell) => ({
        ...cell,
        value: cell.location_id
    }));


    const dispatch = useDispatch();

    const handleScan = (result: string) => {
        const filteredResult = config.barcode_filter(result);
        /**
         * Scan the barcode and search for pallet with that label
         */
        const layer = activePallets.find((p) => p.label === filteredResult);

        if (!layer) {
            toast.info("Pallet not found");
            return;
        }

        setQuery((prev) => ({ ...prev, layer_id: layer.id }));

    };

    const allocateLayerToLocation = useCallback(() => {
        const location = locations.find((cell) => cell.location_id === query.new_location_id);

        if (!location) {
            return;
        }

        const { stage, location_id } = location;
        const { layer_id } = query;

        dispatch(addLayerLocationHistory({ layer_id, stage, location_id, include_children: false }) as any).then(() => {
            refreshAllocatedLayers();
            // deselectAll();
            setModal(false);
        });

    }, [query.layer_id, query.new_location_id]);

    const form = [
        {
            type: "element",
            el: <QRCodeScanner onScan={handleScan} />
        },
        {
            type: "radio",
            label: "Pallet",
            name: "layer_id",
            options: activePallets.map((p) => ({ label: p.label, value: p.id }))
        },
        {
            type: "radio",
            label: "New Location",
            name: "new_location_id",
            options: transformedLocations,
        },
    ];

    return <>
        <Modal isOpen={modalOpen} size="md">
            <ModalHeader >Scan to move pallets</ModalHeader>
            <ModalBody>
                <MetaForm
                    meta={form}
                    object={query}
                    config={config}
                    setValue={(field, value) => setQuery((prev) => ({ ...prev, [field]: value }))}
                />
            </ModalBody>
            <ModalFooter>
                <Button color="primary" disabled={!query.new_location_id || !query.layer_id} onClick={() => allocateLayerToLocation() }>Allocate</Button>
                <Button color="light" onClick={() => setModal(false)}>Cancel</Button>
            </ModalFooter>
        </Modal>
        <Button className="my-1 me-2 text-nowrap" disabled={activePallets.length === 0} style={{ backgroundColor: "#2b83b3" }} onClick={() => setModal(true)}><FontAwesomeIcon icon={faBarcodeRead} /> Scan to move</Button>
    </>;

}
