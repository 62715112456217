/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Table } from "reactstrap";
import { listLayers } from "../../../actions/Layers/actions";
import useConfig, { useTenantFormOptions } from "../../../actions/Tenants/config/configHook";
import { isFilledArray } from "../../../utils";
import LayerSortableHeader from "../../Helper/LayerSortableHeader";
import { Loading } from "../../Helper/Loading";
import { PaginationRow } from "../../Helper/PaginationRow";
import { TableCell, TableHeaderCell } from "../../Helper/Table";
import { useSwitchTabContext } from "./fields/useSwitchTab";
import LayerListCta from "./LayerListCta";

export const DataTestId = {
    TAB: "TAB",
    TAB_CONTENT: "TAB_CONTENT",
};

export default function LayerList() {
    return <LayerTable />;
}


export function LayerTable() {
    const isLoading = useSelector<any, any>((state) => state.layers.isLoading);
    const layers = useSelector<any, any>((state) => state.layers.items);
    const config = useConfig();
    const dispatch = useDispatch();
    const filter = useSelector<any, any>((state) => state.layers.filter);
    const navigate = useNavigate();
    const form_options = useTenantFormOptions();
    const stage = config.get_stage(filter.location);

    const { fields, activeTab } = useSwitchTabContext();

    // Overwrite hardcoded fields with managed fields from the DB
    if (!layers || !stage) {
        return null;
    }

    const updateLimit = (limit) => {
        dispatch(listLayers({ offset: 0, limit }));
    };

    const onPageChanged = (data) => {
        const { currentPage, pageLimit } = data;
        const offset = Math.max((currentPage - 1) * pageLimit, 0);
        if (filter.offset !== offset) {
            dispatch(listLayers({ offset }));
        }
    };
    // sometimes needed when switching tenant
    if (!fields) {
        return null;
    }
    const values = (p) => fields.map((i, index) => <TableCell key={index}>
        <div onClick={() => navigate(`/layer/${p.id}`)} className="clickable">
            {i.set_value({
                config,
                layer: p,
                check: p.latest_check, // * To be used in check tables and layer overview
                fruit: null, // * Fruit list is not available on overview screen
                children: null, // * children is only available on layer index page
                form_options // * used to translate form values to labels
            }).display_component()}
        </div></TableCell>);

    const header = () => fields.map((i, index) => <LayerSortableHeader key={index} field={i} />);
    const hasCTA = isFilledArray(activeTab?.cta_form);
    return <div className="pt-4 d-flex flex-column" css={css`min-height: 70vh;`} >
        <div >

            <Table size="sm" responsive={true} borderless={true}>
                <thead>
                    <tr>
                        {header()}
                        {hasCTA && <TableHeaderCell>&nbsp;</TableHeaderCell>}
                    </tr>
                </thead>
                {!isLoading && <tbody>
                    {layers.results.map((i, index) => <tr key={index} title={i.id} >
                        {values(i)}
                        {hasCTA && <td className="text-end">
                            {/* <Button size="sm" onClick={() => navigate(`/layer/${i.id}`)}>View</Button> */}
                            <Button color="primary" size="sm" onClick={() => navigate(`/layer/tab/${activeTab?.value}/cta/${i.id}`)}>{activeTab?.cta_form_title}</Button>
                        </td>}
                    </tr>)}
                </tbody>}
            </Table >
            {isLoading && <Loading />}
        </div>
        <PaginationRow results={layers.results} totalCount={layers.count} filter={filter} onPageChanged={onPageChanged} updateLimit={updateLimit} />
        <LayerListCta />
    </div>;
}
