/** @jsxImportSource @emotion/react */
import { faEye } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button, Modal, ModalBody, ModalFooter, ModalHeader
} from "reactstrap";
import { listLayers } from "../../../actions/Layers/actions";
import { VIEW } from "../../../actions/Layers/constants";
import useConfig from "../../../actions/Tenants/config/configHook";

export default function ViewStrategyModal() {
    const config = useConfig();
    const [isOpen, setModal] = useState(false);
    const filter = useSelector<any, any>((state) => state.layers.filter);
    const dispatch = useDispatch();
    // const selectedFruitTypes = useFruitTypes();
    const _setQuery = (view_strategy, layer_type) => {
        dispatch(listLayers({
            ...filter, view_strategy, layer_type, offset: 0
        }));
        setModal(false);
    };

    const the_stage = config.get_stage(filter.location);

    if (!the_stage?.view_strategies) {
        return null;
    }

    const _display_strategy = filter.view_strategy === VIEW.SELF || !filter.view_strategy ? "" : ` - ${filter.view_strategy}`;
    return (
        <div className="pb-2 pb-md-0 pe-2">
            <Button color="light" className="text-nowrap" onClick={() => setModal(true)}>
                <FontAwesomeIcon icon={faEye} /> {filter.layer_type} {_display_strategy}
            </Button>
            <Modal isOpen={isOpen} size="xl">
                <ModalHeader >Table view</ModalHeader>
                <ModalBody>
                    {the_stage.view_strategies.map((i, index) => <div key={index} className="pb-4">
                        <h4 className="text-muted mb-0">{i.title}</h4>
                        <p className="mb-1">{i.description}</p>
                        <Button size="sm" color="primary" outline={filter.layer_type !== i.layer_type || filter.view_strategy !== i.view_strategy} onClick={() => _setQuery(i.view_strategy, i.layer_type)}>Select view</Button>
                    </div>)}
                </ModalBody>
                <ModalFooter>
                    <Button color="light" onClick={() => setModal(false)}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}
