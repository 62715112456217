/** @jsxImportSource @emotion/react */
import { useMsal } from "@azure/msal-react";
import { css } from "@emotion/react";
import { faBars, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Link, NavLink, useLocation, useNavigate
} from "react-router-dom";
import { Button } from "reactstrap";
import { logoutUser, restoreTenant } from "../../../actions/Auth/actions";
import useConfig from "../../../actions/Tenants/config/configHook";
import { headerLinks } from "../../../actions/Tenants/config/constants";
import logo from "../../../img/logo-experience-fruit-quality.png";
import { isFilledArray } from "../../../utils";
import FindLayerBox from "../Layers/FindLayerBox";
import FruitFilter from "../Layers/FruitFilter";
import GeneralLayerFilter from "../Layers/GeneralLayerFilter";

export default function Header({ blueBackground, showLayerFilters }) {
    const [open, toggleNav] = useState(false);

    let backgroundColor = "white";
    if (blueBackground) {
        backgroundColor = open ? "#f8f8f8" : "#f4f9ff";
    }


    return <div css={css`box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.12); transition: color 0.3s ease-in; background-color:${backgroundColor}`}>
        <div className="container py-4">
            {/* MOBILE NAv */}
            <div className="d-lg-none">
                <div className="d-flex align-items-start justify-content-between" css={css`min-height: 3.6rem;`}>
                    <div>
                        <Link to="/" >
                            <img src={logo} alt="" css={css`height: 48px; padding-bottom: 8px;`} />
                        </Link>
                    </div>
                    <div className="">
                        <NavToggle open={open} toggleNav={toggleNav} ></NavToggle>
                    </div>
                </div>
                {open
                    && <div className="w-100">
                        <div className="navbar">
                            <ul className="navbar-nav w-100">
                                <NavMainLinks />
                                <NavSecondaryLinks />
                            </ul>
                        </div>
                    </div>
                }

            </div>
            {/* TABLET + DESKTOP NAV */}
            <div className="d-none d-lg-flex align-items-center justify-content-start" css={css`min-height: 3.6rem;`}>
                <div>
                    <Link to="/" >
                        <img src={logo} alt="" css={css`height: 48px; padding-bottom: 8px;`} />
                    </Link>
                </div>
                {!open
                    && <div css={css`padding-left: 2em;`}>
                        <div className="navbar navbar-expand-lg">
                            <ul className="d-flex navbar-nav justify-content-end w-100">
                                <NavMainLinks />
                            </ul>
                        </div>
                    </div>
                }
                {!open && <div className="ms-auto rounded d-flex align-items-center justify-content-center">
                    {showLayerFilters
                        && <>
                            <FruitFilter />
                            <FindLayerBox />
                            <div className="px-1">
                                <GeneralLayerFilter />
                            </div>
                        </>
                    }
                </div>}
                {open
                    && <div className="ms-auto">
                        <div className="navbar navbar-expand-lg">
                            <ul className="d-flex navbar-nav justify-content-end w-100">
                                <NavSecondaryLinks />
                            </ul>
                        </div>
                    </div>
                }
                <div className="ms-3">
                    <NavToggle open={open} toggleNav={toggleNav} ></NavToggle>
                </div>
            </div>
        </div>
    </div>;
}

Header.defaultProps = {
    blueBackground: false,
    showLayerFilters: false,
};

Header.propTypes = {
    blueBackground: PropTypes.bool,
    showLayerFilters: PropTypes.bool,
};


const NavItemCSS = css`
    color: black;
    min-width: 2.5rem;
`;

function NavToggle({ open, toggleNav }) {
    return (

        <Button color="light" css={NavItemCSS} onClick={() => toggleNav(!open)}>
            {!open && <span><FontAwesomeIcon icon={faBars} /></span>}
            {open && <span><FontAwesomeIcon icon={faTimes} /></span>}
        </Button>
    );
}
NavToggle.propTypes = {
    open: PropTypes.bool,
    toggleNav: PropTypes.func,
};


const GeneralLink = ({ path, label }) => <li className="nav-item" key={path}><NavLink className="nav-link" to={path}>{label}</NavLink></li>;
GeneralLink.propTypes = {
    path: PropTypes.string,
    label: PropTypes.string,
};

const generalLinks = {
    [headerLinks.home]: (label) => <GeneralLink path="/" label={label || "Overview"} />,
    [headerLinks.mailbox]: (label) => <GeneralLink path="/mailbox" label={label || "Mailbox"} />,
    [headerLinks.insights]: (label) => <GeneralLink path="/power-bi" label={label || "Insights"} />,
    [headerLinks.ripeWise]: (label) => <GeneralLink path="/ripening-cell" label={label || "RipeWise"} />,
    [headerLinks.waste]: (label) => <GeneralLink path="/waste" label={label || "Waste"} />,
    [headerLinks.waste_trops]: (label) => <GeneralLink path="/waste-trops" label={label || "Waste"} />,
    [headerLinks.ripening]: (label) => <GeneralLink path="/ripening" label={label || "Ripening"} />,
    [headerLinks.labelling]: (label) => <GeneralLink path="/labelling" label={label || "Labelling"} />,
    [headerLinks.packinglist]: (label) => <GeneralLink path="/packing-list" label={label || "Packing List"} />,
    [headerLinks.deviceManagement]: (label) => <GeneralLink path="/device-managment" label={label || "Device Management"} />,
    [headerLinks.tenants]: (label) => <GeneralLink path="/tenants" label={label || "Tenants"} />,
    [headerLinks.settings]: (label) => <GeneralLink path="/settings" label={label || "Settings"} />,
    [headerLinks.admin]: (label) => <GeneralLink key={headerLinks.admin} path="/admin" label={label || "Admin"} />,
};


export function NavSecondaryLinks() {
    const location = useLocation();
    const navigate = useNavigate();
    const { user, authenticated, isLoading } = useSelector((state) => state.auth);
    const config = useConfig();
    const dispatch = useDispatch();
    const { instance } = useMsal();

    const testing = user.email && user.email.endsWith("experiencedata.nl") && user.tenant.tenant_id !== "a6aff297-ac1b-4e81-88a6-420591d31f31";

    const logoutHandler = () => {
        // make sure to redirect to login so that the redirect_to=/add-check/134/summary param is not set to the currect page
        navigate("/login");
        dispatch(logoutUser());
        instance.logoutRedirect();
    };

    const restoreTenantAndNavigate = () => {
        dispatch(restoreTenant()).then(() => navigate(`/tenants/${user.tenant.tenant_id}`));

    };


    if (!authenticated) {
        // also show login item when loading, this will prevent "flickering" of the header during page load
        return [
            (location.pathname !== "/login" || isLoading) && <li className="nav-item" key="login">
                <NavLink className="nav-link" to="/login" >Login</NavLink>
            </li>,
        ];
    }
    const links = { ...generalLinks,
        [headerLinks.logout]: () => <li className="nav-item" key="logout">
            <button className="nav-link link btn btn-link " onClick={() => logoutHandler()} href="#"><span className="text-danger">Logout</span></button>
        </li>,
        [headerLinks.stopTest]: () => <li className="nav-item" key="stop-test">
            <button className="nav-link link btn btn-link " onClick={() => restoreTenantAndNavigate()} href="#"><span className="text-warning">Stop test as {user.tenant.tenant_name}</span></button>
        </li>,
    };

    let linksToShow = [];


    // use urls configuerd in wizard
    if (isFilledArray(config.root_config.secondary_header_links)) {
        const secondary_header_links_to_show = config.root_config.secondary_header_links.filter((link) => !link.roles?.length || link.roles.includes(user.role));
        linksToShow = [...secondary_header_links_to_show, ...(testing ? [{ path: headerLinks.stopTest, label: null }] : [])];
        return linksToShow.map((item) => links[item.path](item.label));
    }

    // !warning do not use .append() here as it will add a stopTest button for every render
    linksToShow = [...config.headerSecondaryLinks, ...(testing ? [headerLinks.stopTest] : [])];

    return linksToShow.map((path) => links[path](null));
}

export function NavMainLinks() {
    const { user } = useSelector((state) => state.auth);
    const config = useConfig();


    // use urls configuerd in wizard
    if (isFilledArray(config.root_config?.primary_header_links)) {
        return config.root_config?.primary_header_links.map((item) => generalLinks[item.path](item.label));
    }

    const getDynamicLinks = () => (user.tenant.show_powerbi_link ? [headerLinks.insights] : []);

    const linksToShow = [...config.headerMainLinks, ...getDynamicLinks()];

    return linksToShow.map((path) => {
        return generalLinks[path](null);
    });
}
