/** @jsxImportSource @emotion/react */
import { faHome } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useParams } from "react-router-dom";
import {
    Breadcrumb, BreadcrumbItem
} from "reactstrap";

const RipeWiseBreadcrumbs = ({ currentLocation }: {currentLocation: any}) => {
    const navigate = useNavigate();
    const { location_id } = useParams();
    return <Breadcrumb className="my-3">
        <BreadcrumbItem href="#" tag="a" onClick={() => navigate(`/layer`)}>
            <FontAwesomeIcon icon={faHome} />
        </BreadcrumbItem>
        {!currentLocation && <BreadcrumbItem href="#" tag="span" >Cells</BreadcrumbItem>}
        {currentLocation && <BreadcrumbItem href="#" tag="a" onClick={() => navigate(`/ripening-cell`)}>Cells</BreadcrumbItem>}
        {currentLocation && currentLocation?.location_id === location_id && <BreadcrumbItem active={true} tag="span" onClick={() => navigate(`/ripening-cell/${currentLocation.location_id}`)}>
            <span>{currentLocation.label}</span>
        </BreadcrumbItem>}
    </Breadcrumb>;

};

export default RipeWiseBreadcrumbs;
