/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { useRef, useState } from "react";
import { components } from "react-select";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";
import { Badge } from "reactstrap";
import { LAYER_TYPE, Layer } from "../../actions/Layers/constants";
import { SelectOption } from "../../actions/Tenants/config/constantsTyped";
import { matchesPattern } from "../../utils/formValidation";
import { toast } from "../../utils/toast";
import { SearchIcon } from "../Helper/SearchIcon";
import { useSearchLayersDebounced } from "./utils";

export type SingleLayerAutoCompleteProps = {
    type: string | null,
    setLayer: (layer: Layer) => void,
    layer?: Layer,
    isMulti?: false,
    disabled?: boolean,
    overwrite?: boolean,
    creatable?: boolean,
    label_format?: string,
    invalid_hint?: string,
    isClearable?: boolean,
};

export type MultiLayerAutoCompleteProps = {
    type: string | null,
    setLayer: (layer: Layer[]) => void,
    layer: Layer[],
    isMulti: true,
    disabled?: boolean,
    overwrite?: boolean,
    creatable?: boolean,
    label_format?: string,
    invalid_hint?: string,
    isClearable?: boolean,
};

type LayerAutoCompleteProps = {
    type?: LAYER_TYPE | null,
    setLayer: (layer: Layer | Layer[]) => void,
    layer?: Layer | Layer[],
    isMulti?: boolean,
    disabled?: boolean,
    overwrite?: boolean,
    creatable?: boolean,
    label_format?: string,
    invalid_hint?: string,
    isClearable?: boolean,
};

export interface LayerAutoComplete {
    (props: SingleLayerAutoCompleteProps): JSX.Element,
    (props: MultiLayerAutoCompleteProps): JSX.Element,
    (props: LayerAutoCompleteProps): JSX.Element,
}

export type LayerOption = SelectOption & Layer;


// * Only show clear indicator when there is value set
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ClearIndicator = (_: any) => {
    return null;
    // const value = props.getValue();
    // if (value && value.label) {
    //     return null;
    // }
    // return <components.ClearIndicator {...props} />;
};
// * Replace dropdown indicator with search icon
const DropdownIndicator = (props: any) => {
    if (props.selectProps.isLoading) {
        return <span></span>;
    }
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <SearchIcon />
            </components.DropdownIndicator>
        )
    );
};


// * some styling for the dropdown
const customStyles = {
    control: (base: any) => ({
        ...base,
        flexDirection: "row-reverse",
    }),
    clearIndicator: (base: any) => ({
        ...base,
        position: "absolute",
        right: 0,
    }),
};

// Format type from layer
const formatOptionLabel = (props) => {
    const { label } = props;
    if (!label) {
        return <span className="text-muted opacity-40">Search...</span>;
    }
    return (
        <div className="d-flex align-items-center">
            <div className="me-auto">{label}</div>
            {props.type
                && <div className="text-muted" css={css`font-size: 12px;`}><Badge size="xs">{props.type.replace(/_/g, " ")}</Badge></div>
            }
        </div>
    );
};
export const LayerAutoComplete: LayerAutoComplete = (({
    type,
    setLayer,
    layer,
    disabled = false,
    overwrite = true,
    creatable = true,
    label_format = undefined as string | undefined,
    invalid_hint = undefined as string | undefined,
    isMulti = false,
    isClearable = true,
}: LayerAutoCompleteProps) => {
    /**
     * prop validation and layer option creation
     */
    const getLayerOption = (layer: Layer): LayerOption => ({ ...layer, value: layer.label, label: layer.label, type: layer.type });
    let layerOption: LayerOption | LayerOption[] | undefined;
    if (isMulti) {
        layerOption = ((layer || []) as Layer[]).map(getLayerOption);
    } else {
        layerOption = layer ? getLayerOption(layer as Layer) : layer;
    }

    /**
     * initialize the ref and the state
     */
    const errorTimer = useRef<number>();
    const [errorState, setErrorState] = useState(false);

    // set error state to true and set a timer to set it back to false
    const setErrorStateTrue = () => {
        setErrorState(true);
        clearTimeout(errorTimer.current);
        errorTimer.current = setTimeout(() => {
            setErrorState(false);
        }, 5000) as unknown as number;
    };

    /**
     * handle layer selection
     */
    const onLayerSelect = (options: Readonly<LayerOption> | ReadonlyArray<LayerOption> | null) => {
        const getNewOption = (option: LayerOption, layer: LayerOption = {} as LayerOption): LayerOption => {
            let newOption: LayerOption = {
                ...layer, // keep what was already being filled
                label: option.value, // use option value as layer name
                fruit_type: option.fruit_type || layer?.fruit_type || undefined, // make sure there is a fruit type  when fetching historic data
                type: option.type || layer.type, // make sure there is a layer type when fetching historic data
            };
            if (overwrite) {
                newOption = { ...newOption, ...option };
                delete (newOption as any).__isNew__;
                delete (newOption as any).value;
            }
            return newOption;
        };
        const getNewOptions = (options: LayerOption[]) => {
            const newOptions: LayerOption[] = [];
            options.forEach((option) => {
                let correspondingLayerOption: LayerOption | undefined;
                if (Array.isArray(layerOption)) {
                    correspondingLayerOption = layerOption.find((l) => l.label === option.label);
                }
                newOptions.push(getNewOption(option, correspondingLayerOption));
            });
            return newOptions;
        };
        const checkLabelFormat = (option: LayerOption) => {
            if (option && label_format && (option as any).__isNew__ && !matchesPattern(option.value, label_format)) {
                // if the label format is not matched...
                if (errorState === false) {
                    // show an error message if not showed less then 5 sec ago
                    toast.error(`${invalid_hint}`);
                    setErrorStateTrue();
                }
                return false;
            }
            return true;
        };
        let newLayer;
        if (Array.isArray(options)) {
            options = options || [];
            if (options.every(checkLabelFormat)) {
                newLayer = getNewOptions(options as LayerOption[]);
                setLayer(newLayer);
            }
        } else {
            options = options || layerOption as LayerOption;
            if (checkLabelFormat(options as LayerOption)) {
                newLayer = getNewOption(options as LayerOption, layerOption as LayerOption);
                setLayer(newLayer);
            }
        }
    };

    const components = {
        IndicatorSeparator: () => null,
        DropdownIndicator,
        ClearIndicator,
        CrossIcon: () => null,
    };

    const [searchLayersDebounced] = useSearchLayersDebounced();
    /**
     * render the component
     */
    if (creatable) {

        return (
            <AsyncCreatableSelect
                defaultOptions={[] as LayerOption[]}
                loadOptions={(value) => searchLayersDebounced(value, type)}
                isClearable={isClearable}
                onChange={onLayerSelect}
                isDisabled={disabled}
                value={layerOption}
                isMulti={isMulti}
                components={components}
                styles={customStyles}
                formatOptionLabel={formatOptionLabel}
            />
        );
    }

    return <AsyncSelect
        defaultOptions={[] as LayerOption[]}
        loadOptions={(value) => searchLayersDebounced(value, type)}
        isClearable={true}
        onChange={onLayerSelect}
        isDisabled={disabled}
        value={layerOption}
        isMulti={isMulti}
        components={components}
        styles={customStyles}
        formatOptionLabel={formatOptionLabel}
    />;
}) as LayerAutoComplete;

export default LayerAutoComplete;
