/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Badge } from "reactstrap";
import { checkHasFruit } from "../../../../actions/Checks/util";
import { CHECK_CARD_TYPE, CHECK_LOCATION, LAYER_TYPE, isArtLocation } from "../../../../actions/Layers/constants";
import useConfig from "../../../../actions/Tenants/config/configHook";
import { path_advance_ripening, path_summary } from "../../../../actions/Tenants/config/constants";
import mq from "../../../../constants/mediaqueries";
import { Loading } from "../../../Helper/Loading";

const CheckCard = ({ check, layer, card_type }) => {
    const navigate = useNavigate();

    const getFlagModifier = (check) => {
        // * get status from internal data processing
        if (check.status) {
            return `flag-${check.status}`;
        }

        return "";

        // TODO: remove this logic
        // prefer manual flag
        // if (check.flag === "flag-danger") {
        //     return "flag-danger";
        // }
        // // List all check properties and find the one that starts with flag_
        // const check_flags = Object.keys(check).filter((i) => i.startsWith("flag_"));

        // if (check_flags.length > 0) {
        //     const danger_flag = check_flags.find((i) => check[i] === "danger");
        //     if (danger_flag) {
        //         return "flag-danger";
        //     }

        //     const warning_flag = check_flags.find((i) => check[i] === "warning");
        //     if (warning_flag) {
        //         return "flag-warning";
        //     }
        // }

        // if (!checkHasFruit(check)) return "flag-light";

        // return check.flag;

    };

    const getInnerCard = (check) => {

        if (card_type === CHECK_CARD_TYPE.LABEL) {
            return <div className="display-6 fw-bold" css={css`font-size: 22px;`}>{layer.label}</div>;
        } if (card_type === CHECK_CARD_TYPE.SIZE_CATEGORY_OR_LABEL) {
            if (check.size_category) {
                return <div className="display-6 fw-bold" css={css`font-size: 22px;`}>{check.size_category}</div>;
            }
            return <div className="display-6 fw-bold" css={css`font-size: 22px;`}>{layer.label}</div>;
        } if (card_type === CHECK_CARD_TYPE.SIZE_CATEGORY) {
            return <div className="display-6 fw-bold" css={css`font-size: 22px;`}>{check.size_category}</div>;
        }
        return <div>
            <div className="display-6 fw-bold" css={css`font-size: 22px;`}> {dayjs(check.created).format("D")}</div>
            <div className="text-muted fw-bold">{dayjs(check.created).format("MMM")}</div>
        </div>;
    };

    return <div
        className={`card-alert --${getFlagModifier(check)} text-center py-2 px-3 clickable me-3 mb-3 ${(checkHasFruit(check)) ? "" : "opacity-60"}`}
        css={css`min-width: 4.3rem; border-top-width: 6px; border-radius: 8px;`}
        title={`Check ${check.test_id}`}
        onClick={() => {
            if (isArtLocation(check.location)) {
                navigate(`/layer/${check.layer_id}/add-check/${check.test_id}/${path_advance_ripening}`);
            } else {
                navigate(`/layer/${check.layer_id}/add-check/${check.test_id}/${path_summary}`);
            }
        }} >
        {getInnerCard(check)}
    </div>;

};

CheckCard.propTypes = {
    check: PropTypes.object,
    card_type: PropTypes.string,
    layer: PropTypes.object,
};

function filterOnlyLatestCheckPerChildPerLocation(checks, card_type) {
    // DATE is default card type, in that sense we want to show all checks
    if (!card_type || card_type === CHECK_CARD_TYPE.DATE) {
        return checks;
    }

    const getCompositeKey = (check) => {
        // do not be confused by label, it is label of the card, not layer label
        const { layer_id, label, location, size_category } = check;
        switch (card_type) {
        case CHECK_CARD_TYPE.SIZE_CATEGORY_OR_LABEL:
            return `${layer_id}_${size_category || label}_${location}`;
        case CHECK_CARD_TYPE.SIZE_CATEGORY:
            return `${layer_id}_${size_category}_${location}`;
        case CHECK_CARD_TYPE.LABEL:
            return `${layer_id}_${label}_${location}`;
        default:
            return `${layer_id}_${location}`;
        }
    };
    // Create a Map to store the latest checks for each location and child
    const latestChecksMap = new Map();

    // Iterate through the data and update the latest checks based on configuration
    checks.forEach((check) => {
        const { location_config } = check;
        if (location_config?.only_show_latest_check_on_child) {
            const compositeKey = getCompositeKey(check);
            if (!latestChecksMap.has(compositeKey) || latestChecksMap.get(compositeKey).test_id < check.test_id) {
                latestChecksMap.set(compositeKey, check);
            }
        }
    });

    // Use filter to exclude previous checks from the original array
    return checks.filter((check) => {
        const { location_config } = check;
        if (!location_config) return true;

        // This only applies for specific locations
        if (!location_config?.only_show_latest_check_on_child) {
            return true;
        }
        const compositeKey = getCompositeKey(check);
        return latestChecksMap.has(compositeKey) && latestChecksMap.get(compositeKey).test_id === check.test_id;
    });
}

export default function LayerChecks({ subconfig }) {
    const config = useConfig();
    const checks = useSelector((state) => state.layers.checks);
    const layer = useSelector((state) => state.layers.current);
    const isLoading = useSelector((state) => state.layers.isLoading);
    const checksLoading = useSelector((state) => state.layers.checksLoading);

    if (isLoading || !checks || checksLoading) {
        return <Loading />;
    }

    const { filter_field, filter_location } = subconfig;
    // put ALl as last, and rename to other
    let stages = [...config.stages];

    // use spread operator to prevent deep clone issues
    const ALL = { ...stages.shift() };
    ALL.text = "Other";
    ALL.value = "other";
    stages.push(ALL);

    // is this check on a child or on the layer itself?
    const addData = (check) => {

        const child = getChild(check);

        // Make sure there is a fruit_type set on the layer (inherit from parent)
        const layer_for_get_location = child ? { ...child, fruit_type: child.fruit_type || layer.fruit_type } : layer;
        const location_config = config.get_location(layer_for_get_location, check);

        const card = <CheckCard layer={child || layer} check={check} card_type={subconfig.card_type} />;

        return {
            ...check,
            location: getStage(check, location_config),
            location_config,
            layer: child || layer,
            card,
            is_manual: check.is_manual === true // lets be more strict about what is true or false here (null should be false to)
        };
    };

    const getStage = (check, location_config) => {
        // Either the value of the location is a stage
        if (stages.some((stage) => stage.value === check.location)) {
            return check.location;
        }

        // Or the allocate_stage prop is set
        if (location_config && stages.some((stage) => stage.value === location_config.allocate_stage)) {
            return location_config.allocate_stage;
        }
        return "other";
    };

    const getChild = (test) => layer.children.find((i) => i.id === test.layer_id);

    // label every location that is unconfigured as other
    let theChecks = checks
        .filter((i) => (filter_field && layer.id !== i.layer_id ? i[filter_field] === layer[filter_field] : true))
        .filter((i) => (filter_location && layer.id !== i.layer_id ? i.location === filter_location : true))
        .map(addData)
        .filter((i) => (i.layer.type !== LAYER_TYPE.PURCHASE_ORDER && i.location !== CHECK_LOCATION.LAB_CHECK))
        .sort((a, b) => a.test_id - b.test_id);

    // If we do not show a date on the card... we want to only show the latest label e.g. advance ripening
    theChecks = filterOnlyLatestCheckPerChildPerLocation(theChecks, subconfig.card_type);

    // Order all checks
    stages = stages.map((stage) => ({
        ...stage,
        checks: theChecks.filter((check) => check.location === stage.value)
    })).map((stage) => ({
        ...stage,
        manual_checks: stage.checks.filter((check) => check.is_manual),
        device_checks: stage.checks.filter((check) => !check.is_manual),
    }));

    return <div className="w-100 pb-5">

        <div css={css`border-radius:8px;`} className="bg-body shadow p-5" >
            <CheckWrapper
                title={<h5 className="mb-0 fw-bold">Stage</h5>}
            >
                <h5 className="mb-0 fw-bold">Checks</h5>
            </CheckWrapper>
            {stages.filter((stage) => !(stage.value === "other" && stage.checks.length === 0)).map((stage) => <CheckWrapper
                key={stage.value}
                title={<Badge color="light" className="text-black">{stage.text}</Badge>}
            >
                {stage.device_checks.length > 0 && <div className="d-flex flex-wrap justify-content-start">
                    {stage.device_checks.map((check) => <div key={check.test_id}>{check.card}</div>)}
                </div>}
                {stage.manual_checks.length > 0 && <div className="d-flex flex-wrap justify-content-start">
                    {stage.manual_checks.map((check) => <div key={check.test_id}>{check.card} </div>)}
                </div>}
                {stage.checks.length === 0 && <span>-</span>}
            </CheckWrapper>)}
        </div>
    </div >;
}

LayerChecks.propTypes = {
    subconfig: PropTypes.object,
};


function CheckWrapper({ title, children }) {
    return <div className="d-flex flex-wrap py-3 justify-content-start" >
        <div className="pb-3 pb-lg-0" css={mq({ width: ["100%", "100%", "100%", "25%"] })} >{title}</div>
        <div css={mq({ width: ["100%", "100%", "100%", "75%"] })} > {children}</div>
    </div>;
}

CheckWrapper.propTypes = {
    title: PropTypes.element,
    children: PropTypes.any,
};


