/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
    Button,
    Modal, ModalBody, ModalFooter, ModalHeader
} from "reactstrap";
import {
    getLayer,
    patchLayer,
    resetLayerState
} from "../../../actions/Layers/actions";
import { applyDisplayFilterFields } from "../../../actions/Tenants/config/applyDisplayFilter";
import useConfig from "../../../actions/Tenants/config/configHook";
import MetaForm, { metaFormIsValid } from "../../Forms/MetaForm";
import { Loading } from "../../Helper/Loading";
import { useSwitchTabContext } from "./fields/useSwitchTab";

const LayerListCta = () => {
    const config = useConfig();

    const [isOpen, toggle] = useState(false);
    const params = useParams();
    const dispatch = useDispatch();
    const { normalizeUrl, activeTab } = useSwitchTabContext();


    const layer = useSelector((state: any) => state.layers.current);
    const layer_config = config.get_layer_config(layer);
    const meta_form = applyDisplayFilterFields(activeTab?.cta_form, {});

    useEffect(() => {
        if (params.cta_layer_id) {
            toggle(true);
            dispatch(resetLayerState());
            dispatch(getLayer(params.cta_layer_id, false) as any);
        } else {
            toggle(false);
        }
    }, [params.cta_layer_id]);

    const setValue = (field, value) => dispatch(patchLayer(layer.id, field, value, false) as any);
    const onDebounce = (field, value) => {
        const field_config = meta_form.find((i) => i.name === field);
        if (field_config && metaFormIsValid([field_config], { [field]: value })) {
            return dispatch(patchLayer(layer.id, field, value, true) as any);
        }
        return Promise.resolve();
    };

    const onSubmit = () => {
        if (meta_form && !metaFormIsValid(meta_form, layer)) return;
        // dispatch(triggerLayerBusinessRules(layer.id) as any);
        toggle(false);
    };

    const onClose = () => normalizeUrl();

    const loading = !layer_config || !layer;
    return (
        <Modal isOpen={isOpen} size="md">
            { loading && <Loading /> }
            { !loading && <>
                <ModalHeader toggle={() => () => onClose()} onClick={() => onClose()}>{activeTab?.cta_form_title} {layer_config.text} {layer.label} </ModalHeader>
                <ModalBody>
                    <MetaForm
                        meta={meta_form}
                        object={layer}
                        config={config}
                        setValue={setValue}
                        onDebounce={onDebounce}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button color="light" onClick={() => onClose()}>Cancel</Button>
                    <Button color="success" onClick={() => onSubmit()}>Save</Button>
                </ModalFooter>
            </>}
        </Modal>
    );
};

export default LayerListCta;
