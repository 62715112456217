import { Reducer, combineReducers } from "redux";
import adminReducer, { AdminState } from "../actions/Admin/reducer";
import authReducer from "../actions/Auth/reducer";
import avocadoReducer from "../actions/Avocados/reducer";
import checkReducer from "../actions/Checks/reducer";
import deviceReducer from "../actions/Devices/reducer";
import facilityLocationsReducer from "../actions/FacilityLocations/reducer";
import labelingReducer from "../actions/Labelling/reducer";
import layerReducer, { LayersState } from "../actions/Layers/reducer";
import mailboxReducer, { MailboxState } from "../actions/Mailbox/reducer";
import packingListReducer from "../actions/PackingList/reducer";
import tenantReducer from "../actions/Tenants/reducer";
import wasteReducer from "../actions/Waste/reducer";

export interface RootState {
    auth: any;
    avocados: any;
    checks: any;
    layers: LayersState;
    tenants: any;
    devices: any;
    labeling: any;
    waste: any;
    packingLists: any;
    mailbox: MailboxState;
    facilityLocations: any;
    admin: AdminState;
}

const rootReducer: Reducer<RootState> = combineReducers({
    auth: authReducer,
    avocados: avocadoReducer,
    checks: checkReducer,
    layers: layerReducer,
    tenants: tenantReducer,
    devices: deviceReducer,
    labeling: labelingReducer,
    waste: wasteReducer,
    packingLists: packingListReducer,
    mailbox: mailboxReducer,
    facilityLocations: facilityLocationsReducer,
    admin: adminReducer,
});

export default rootReducer;
