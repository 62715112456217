import DownloadCsvModel from "./DownloadCsvModel";
import { ActiveSearchBadges } from "./ActiveSearchBadges";
import ViewStrategyModal from "./ViewStrategyModal";

export default function StageActions() {

    return <div className="d-flex align-items-center">
        <ViewStrategyModal />
        <DownloadCsvModel />
        {/* <LayerFilter/> */}
        <ActiveSearchBadges />
    </div>;
}
