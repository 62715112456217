/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faFileArrowDown, faFileArrowUp, faMagnifyingGlass } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
    Badge,
    Button,
    Col, Container, Row,
    Table
} from "reactstrap";
import { Loading } from "../../Helper/Loading";
import { listPackingList, updateFilter } from "../../../actions/PackingList/actions";
import { PaginationRow } from "../../Helper/PaginationRow";
import PackingListReviewModal from "./PackingListReviewModal";
import PackingListUploadModal from "./PackingListUploadModal";

function StatusBadge({ item }) {
    let color = "light";
    if (item.status === "SUCCESS") {
        color = "success";
    } else if (item.status === "FAILED") {
        color = "danger";
    } else if (item.status === "CREATED") {
        color = "warning";
    }
    return <Badge size="sm" color={color}>{item.status}</Badge>;
}


export default function PackingListList() {
    const dispatch = useDispatch();
    const items = useSelector((state: any) => state.packingLists.items);
    const isLoading = useSelector((state: any) => state.packingLists.isLoading);
    const isUpating = useSelector((state: any) => state.packingLists.isUpdating);
    const navigate = useNavigate();
    const [importModal, setImportModal] = useState(false);
    const [previewModal, setPreviewModal] = useState(false);
    const filter = useSelector((state: any) => state.packingLists.filter);

    useEffect(() => {
        if (!isLoading) {
            dispatch(listPackingList(filter) as any);
        }
    }, [filter]);

    const updateLimit = (limit) => {
        dispatch(updateFilter({ ...filter, limit }));
    };

    const download = (item) => {
        window.location.href = item.url;
    };

    const onPageChanged = (data) => {
        const { currentPage, pageLimit } = data;
        const offset = Math.max((currentPage - 1) * pageLimit, 0);
        if (filter.offset !== offset) {
            dispatch(updateFilter({ ...filter, offset }));
        }
    };

    const layerLink = (item) => {
        if (!item.layer_id) {
            return <span>-</span>;
        }
        return <Link to={`/layer/${item.layer_id}`}>{item.label}</Link>;
    };

    const noWrap = css`
        white-space: nowrap; /* Prevents wrapping */
        overflow: hidden; /* Keeps content from spilling out */
        text-overflow: ellipsis; /* Adds ellipsis if content is too long */
        max-width: 150px; /* Adjust as needed */
    `;

    const results = items.results || [];
    return <Container className="py-5">
        <Row className="justify-content-center">
            <Col lg="12">
                <div className="d-flex justify-content-between">
                    <div>
                        <h2 className="">Packing list import</h2>
                        {/* <p style={{ fontStyle: "italic" }}>Efficiently manage and verify your import packing lists with our digital platform. Ensure accuracy and compliance for smooth and optimized import operations.</p> */}
                    </div>
                    <div>
                        <Button className="text-nowrap me-3" color="primary" onClick={() => setImportModal(true)}><FontAwesomeIcon icon={faFileArrowUp} /> Import packing list</Button>
                        <Button className="btn-close my-1" disabled={isUpating || isLoading} size="lg" onClick={() => navigate(`/`)} ></Button>
                    </div>
                </div>
            </Col>
        </Row>
        <div>
            <div css={css`min-height: 70vh;`}>
                {isLoading && <Loading />}
                {!isLoading && <Table size="sm" responsive={true} borderless={true}>
                    <thead>
                        <tr>
                            <th>Template</th>
                            <th>File name</th>
                            <th>Created</th>
                            <th>Container</th>
                            <th css={noWrap}>Purchase order</th>
                            <th>Country</th>
                            <th>Supplier</th>
                            <th>Fruit</th>
                            <th >Status</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {results.map((item) => <tr key={item.id}>
                            <td>{item.template}</td>
                            <td>{item.file_name}</td>
                            <td css={noWrap}>{dayjs(item.created).format("HH:mm DD/MM/YYYY")}</td>
                            <td>{item.container_no || "-"}</td>
                            <td>
                                {layerLink(item)}
                            </td>
                            <td>{item.country}</td>
                            <td css={noWrap}>{item.supplier}</td>
                            <td>{item.fruit_type}</td>
                            <td ><StatusBadge item={item}></StatusBadge></td>
                            <td className="text-end d-flex">
                                <Button css={noWrap} size="sm" className="mx-1" color="light" onClick={() => setPreviewModal(item.id)}><FontAwesomeIcon icon={faMagnifyingGlass} /> View</Button>
                                <Button css={noWrap} size="sm" color="light" onClick={() => download(item)}><FontAwesomeIcon icon={faFileArrowDown} /> Download</Button>
                            </td>
                        </tr>) }
                    </tbody>
                </Table>}
            </div>
            <PaginationRow results={results} totalCount={items.count} filter={filter} onPageChanged={onPageChanged} updateLimit={updateLimit} />
        </div>
        {items?.count === 0 && <div className="text-center py-5">
            <div className="text-center d-flex align-items-center justify-content-center" css={css`min-height: 50vh;`}>
                <div>
                    <h6 className="text-muted">Upload a packing list to get started</h6>
                    <Button color="primary" onClick={() => setImportModal(true)}><FontAwesomeIcon icon={faFileArrowUp} /> Import packing list</Button>
                </div>
            </div>
        </div>}
        <PackingListUploadModal isOpen={importModal} toggle={() => setImportModal(!importModal)} />
        <PackingListReviewModal list_id={previewModal} isOpen={previewModal !== false} toggle={() => setPreviewModal(false)} />
    </Container>;

}
