/** @jsxImportSource @emotion/react */
import { faHistory, faHome } from "@fortawesome/pro-regular-svg-icons";
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, ListGroup, ListGroupItem, Modal, ModalBody, ModalHeader } from "reactstrap";
import { addLayerBrowseHistory } from "../../../../actions/Layers/actions";
import useConfig from "../../../../actions/Tenants/config/configHook";

interface LayerHistoryItemType {
    id: string;
    type: string;
    label: string;
    text: string;
    //   parents?: LayerHistoryItemType[];
}

interface LayerBreadcrumbsProps {
    location_config?: {
        text: string;
    };
    check?: {
        group_label: string;
        location_id: string;
        created: string;
    };
}

const LayerBreadcrumbs: React.FC<LayerBreadcrumbsProps> = ({ location_config = false, check = undefined }) => {
    const dispatch = useDispatch();
    const layer = useSelector((state: any) => state.layers.current);
    const browse_history = useSelector((state: any) => state.layers.browse_history);
    const navigate = useNavigate();
    const config = useConfig();
    const layer_config = config.get_layer_config(layer);

    const [isOpen, setModal] = useState(false);

    const renderLayerLabel = (label: string) => {
        if (label.length > 12) {
            return `${label.slice(0, 4)}...${label.slice(-4)}`;
        }
        return label;
    };

    useEffect(() => {
        if (layer.id && layer_config?.text) {
            dispatch(addLayerBrowseHistory({ ...layer, text: layer_config.text }));
        }
    }, [layer.id, layer_config?.text]);

    if (!layer_config) {
        return null;
    }

    const previous_viewed_layer = browse_history.length > 1 && browse_history.at(-2).id !== layer.id ? browse_history.at(-2) : false;
    const parent = layer.parents && layer.parents.length > 0 ? layer.parents.at(-1) : false;
    const parent_layer_type = parent ? config.get_raw_layer_config(parent.type) : false;

    return (
        <div>
            <div className="d-flex justify-content-between">
                <div>
                    <Breadcrumb className="mt-3 mb-5">
                        <BreadcrumbItem href="#" tag="a" onClick={() => navigate(`/layer`)}>
                            <FontAwesomeIcon icon={faHome} />
                        </BreadcrumbItem>
                        {parent && parent_layer_type && (
                            <BreadcrumbItem href="#" tag="a" onClick={() => navigate(`/layer/${parent.id}`)}>
                                {parent_layer_type.text} {renderLayerLabel(parent.label)}
                            </BreadcrumbItem>
                        )}
                        {!check?.group_label && <BreadcrumbItem href="#" active={!location_config} tag={location_config ? "a" : "span"} onClick={() => navigate(`/layer/${layer.id}`)}>
                            <span>{layer_config.text} {renderLayerLabel(layer.label)}</span>
                        </BreadcrumbItem>}
                        {check?.group_label && <BreadcrumbItem href="#" active={!location_config} tag={location_config ? "a" : "span"} onClick={() => navigate(`/ripening-cell/${check.location_id}/date/${dayjs(check.created).format("DD-MM-YYYY")}`)}>
                            <span>{check.group_label}</span>
                        </BreadcrumbItem>}
                        {typeof location_config === "object" && (
                            <BreadcrumbItem active>
                                <span>{location_config.text} Check</span>
                            </BreadcrumbItem>
                        )}
                    </Breadcrumb>
                </div>
                {previous_viewed_layer && browse_history.length > 2 && (
                    <div>
                        <Breadcrumb className="mt-3 mb-5 d-none d-md-block">
                            <BreadcrumbItem onClick={() => setModal(true)}>
                                <span className="text-muted cursor-pointer">
                                    <FontAwesomeIcon icon={faHistory} /> Viewed items
                                </span>
                            </BreadcrumbItem>
                        </Breadcrumb>
                    </div>
                )}
            </div>

            {browse_history.length > 2 && (
                <Modal size="md" isOpen={isOpen} toggle={() => setModal(false)}>
                    <ModalHeader toggle={() => setModal(false)}>Previous viewed items</ModalHeader>
                    <ModalBody>
                        <ListGroup>
                            {removeDuplicates(browse_history).slice(-8).map((i: LayerHistoryItemType) => (
                                <ListGroupItem href="#" tag="a" onClick={() => navigate(`/layer/${i.id}`)} key={i.id} disabled={i.id === layer.id}>
                                    <div className="d-flex">
                                        <span>{i.text} {i.label}</span>
                                        {i.id !== layer.id && (
                                            <span className="ms-auto">
                                                <FontAwesomeIcon icon={faArrowRight} />
                                            </span>
                                        )}
                                    </div>
                                </ListGroupItem>
                            ))}
                        </ListGroup>
                    </ModalBody>
                </Modal>
            )}
        </div>
    );
};

function removeDuplicates(arrOfObjects: LayerHistoryItemType[]) {
    const uniqueLabels: string[] = [];
    return arrOfObjects.reverse().filter((obj) => {
        const isDuplicate = uniqueLabels.includes(obj.id);
        if (!isDuplicate) {
            uniqueLabels.push(obj.id);
            return true;
        }
        return false;
    }).reverse();
}

export default LayerBreadcrumbs;
