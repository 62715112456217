/** @jsxImportSource @emotion/react */
import { useMemo } from "react";
import { LAYER_STAGE, LAYER_TABS, LAYER_TYPE } from "../../../../actions/Layers/constants";
import { applyDisplayFilterLayerFields } from "../../../../actions/Tenants/config/applyDisplayFilter";
import useConfig from "../../../../actions/Tenants/config/configHook";
import { getFilledArrayOrDefault } from "../../../../utils";
import MetaForm from "../../../Forms/MetaForm";
import Field from "../../Layers/fields/Field";
import FieldConfigForm from "./FieldConfigForm";
import FormConfigForm from "./FormConfigForm";
import ViewStrategyConfigForm from "./ViewStrategyConfigForm";
import { yerOrNoRadio } from "./util";

export const defaultStage = {
    text: "Intake",
    value: "intake",
    meta_display: []
};

export default function OverviewTabConfigForm({ config_data, setConfigData }) {
    const config = useConfig();


    const sortableOptions = useMemo(() => {
        const configuredLayerFields = applyDisplayFilterLayerFields(getFilledArrayOrDefault(config_data.layer_overview_fields));
        return [
            { value: "check.created,DESC", label: "Default [check.created,DESC]" },
            ...configuredLayerFields.filter((i: Field) => i.is_sortable())
                .flatMap((i: Field) => ["ASC", "DESC"].map((direction: string) => {
                    const value = `${i.get_backend_fieldname()[0]},${direction}`;
                    const label = `${i.label} [${value}]`;
                    return { label, value };
                }))
        ];
    }, [config_data.layer_overview_fields]);

    const form = [
        {
            label: "Label",
            description: "What is the label of this tab. Used in the tab.",
            name: "text",
            type: "text"
        },
        {
            label: "Value",
            name: "value",
            description: "What is the slug or identifier of this tab. Used in the database and in urls.",
            type: "single-select",
            options: Object.keys(LAYER_TABS).map((i) => ({ value: LAYER_TABS[i], label: i }))
        },
        {
            label: "Layer type",
            description: "Default layer type to display in tab.",
            name: "layer_type",
            type: "single-select",
            options: [{ value: null, label: "All" }, ...Object.keys(LAYER_TYPE).map((i) => ({ value: LAYER_TYPE[i], label: i }))]
        },
        {
            label: "Filter stages",
            description: "Only show layers in this stage in this tab.",
            multi: true,
            return_array: true,
            options: Object.keys(LAYER_STAGE).map((i) => ({ value: LAYER_STAGE[i], label: i })),
            name: "filter_stages",
            type: "single-select",
        },
        {
            label: "Max days in facility",
            description: "Only show layers that have been in the facility for less then this amount of days. The user can adjust to their liking",
            name: "max_days_in_facility",
            type: "number"
        },
        {
            label: "Default ordering",
            description: "Default field to order on in the overview table.",
            name: "default_ordering",
            type: "single-select",
            options: sortableOptions
        },
        {
            label: "Default Create Layer type",
            description: "Default layer type to create from this tab. Will default to the layer type being used in the filter configured above.",
            name: "default_create_layer_type",
            type: "single-select",
            options: [{ value: null, label: "Same as filter" }, ...Object.keys(LAYER_TYPE).map((i) => ({ value: LAYER_TYPE[i], label: i }))]
        },
        {
            type: "element",
            el: <FieldConfigForm
                fields={config_data.layer_overview_fields || []}
                description="What fields to display on this tab in the table"
                name="layer_overview_fields"
                title="Overview table fields"
                useDisplayFilter={["layer_types", "exclude_layer_types", "fruit_types", "exclude_fruit_types"]}
                setArray={(layer_overview_fields) => setConfigData({ ...config_data, layer_overview_fields })} />
        },

        {
            type: "element",
            el: <ViewStrategyConfigForm
                fields={config_data.view_strategies || []}
                description={<span>Define a list of strategies to select when you click the Eye icon in the layer overview.<br></br> Only view strategy SELF is implemented. Is more usefull if we enable AGGREGATE and EXTRAPOLATE again. To be refined.</span>}
                name="view_strategies"
                title="View strategies"
                setArray={(view_strategies) => setConfigData({ ...config_data, view_strategies })} />
        },
        // TODO: we could opt to drop this, for now only use for columns that are not visible or "special filters"
        {
            type: "element",
            el: <FormConfigForm
                fields={config_data.overview_filter_form || []}
                description="!!Deprecated!! What fields to filter in the overview table."
                name="overview_filter_form"
                title="Filter"
                setArray={(overview_filter_form) => setConfigData({ ...config_data, overview_filter_form })} />
        },
        {
            label: "Call to Action title",
            name: "cta_form_title",
            description: "Title of the call to action form and button.",
            type: "text"
        },
        {
            type: "element",
            el: <FormConfigForm
                fields={config_data.cta_form || []}
                description="Call to action form for overview table."
                name="cta_form"
                title="Filter"
                setArray={(cta_form) => setConfigData({ ...config_data, cta_form })} />
        },
        {
            type: "element",
            el: <FormConfigForm
                fields={config_data.download_csv_fields || []}
                description="What fields to be downloaded."
                name="download_csv_fields"
                title="Download CSV"
                setArray={(download_csv_fields) => setConfigData({ ...config_data, download_csv_fields })} />
        },
        {
            label: "Hide color code",
            name: "hide_color_code",
            description: "Hide color code of this stage in other tabs in LayerStagesColorBadgeField [All_STAGES_STATUS]. You probably want to set this to true for the All tab. ",
            ...yerOrNoRadio
        },
        {
            label: "Status letter",
            name: "status_letter",
            description: "Status letter to display in the overview table.",
            type: "text"
        }


    ];

    return <MetaForm meta={form} setValue={(field, value) => setConfigData({ ...config_data, [field]: value })} object={config_data} config={config} />;
}
