/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactElement } from "react";
import { useSelector } from "react-redux";
import {
    Col, Container, Row
} from "reactstrap";
import { LayerFilter } from "../../../actions/Layers/constants";
import mq from "../../../constants/mediaqueries";
import LayerList from "./LayerList";
import StageActions from "./StageActions";
import { useSwitchTabContext } from "./fields/useSwitchTab";

export const DataTestId = {
    TAB: "TAB",
    TAB_CONTENT: "TAB_CONTENT",
};

export default function StagesTabs() {
    const { tabs, switchTab } = useSwitchTabContext();

    const tabStyle = (tab) => {
        const styles = css`text-align:center;background-color:#e5e8e5;`;
        const responsiveStyles = mq({
            minWidth: ["49%", "48%", "48%", "130px"],
            borderRadius: ["8px", "8px", "8px", "8px 8px 0px 0px"],
            borderBottom: ["0px", "0px", "0px", "2px solid #eee"],
        });
        if (tab.active) {
            const activeStyle = css`font-weight: 900; background-color: #fff;`;
            const activeResponsiveStyle = mq({
                borderBottom: ["0px", "0px", "0px", "2px solid #fff"],
            });
            return [styles, responsiveStyles, activeResponsiveStyle, activeStyle];
        }
        const inActiveStyle = css`cursor:pointer`;
        return [styles, responsiveStyles, inActiveStyle];
    };

    return <Container className="pb-md-5 pb-3">
        <div>
            <Row className="justify-content-center py-3">
                <Col lg="12" >
                    <div className="py-5">
                        <div className="d-flex flex-wrap">
                            {
                                tabs.map((tab, index) => <div key={index} className="px-3 py-3 mb-3 me-0 me-lg-2  mb-lg-0" css={tabStyle(tab)} onClick={() => switchTab(tab)} data-testid={`${DataTestId.TAB}${index}`}>
                                    <h6 className="mb-0 text-no-wrap fw-bold" css={css`opacity: ${tab.active ? 1 : 0.5};`}>{tab.title}  <Count stage={tab as any} /> </h6>
                                    {tab.subtitle && <p className="text-muted py-10">{tab.subtitle}</p>}
                                </div>)
                            }
                        </div>
                        <div className="bg-white px-3 py-3" css={css`border-radius: 0px 8px 8px 8px;`} >
                            <StageActions />
                            <LayerList />
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    </Container>;
}


function Count({ stage }: { stage: any }): ReactElement<any, any> {
    const per_stage = useSelector<any, any[]>((state) => state.layers.per_stage);
    const filter = useSelector<any, LayerFilter>((state) => state.layers.filter);
    let counted;
    if (stage.location === filter.location) {
        // count the layers for current filtered layer type
        counted = per_stage.find((i) => i.stage === stage.location && i.type === filter.layer_type);
    } else {
        // count the layer for default layer type for stage
        counted = per_stage.find((i) => i.stage === stage.location && i.type === stage.layer_type);
    }
    if (stage.location === "") {
        return <></>;
    }

    let value = "-";

    if (counted) {
        value = counted.count;
    }

    return <span className="opacity-60 ms-2">{value}</span>;
}
