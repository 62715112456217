import dayjs from "dayjs";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { applyDisplayFilterLayerFields } from "../../../../actions/Tenants/config/applyDisplayFilter";
import useConfig from "../../../../actions/Tenants/config/configHook";

import { countLayerInStage, FILTER_METHODS, listLayers, resetLayerList, updateFilter } from "../../../../actions/Layers/actions";
import { LayerFilter } from "../../../../actions/Layers/constants";
import { Tab } from "../../../../actions/Tenants/config/constantsTyped";
import { getFilledArrayOrDefault } from "../../../../utils";
import { useFruitTypes } from "../../../../utils/useUserPreferences";
import { useDebounce } from "../../../Forms/utils";
import Field from "./Field";

// Create context
interface SwitchTabContextProps {
    switchTab: (tab: any) => void;
    listLayersDebounced: (value: any) => void;
    tabs: Tab[];
    activeTab?: Tab;
    active_tab?: string;
    fields: Field[];
    submitTabQuery: (query: any) => void;
    personalTabQuery: any;
    normalizeUrl: () => void;

}
const SwitchTabContext = createContext({
    switchTab: () => null,
    listLayersDebounced: () => null,
    tabs: [],
    fields: [],
    submitTabQuery: () => null,
    personalTabQuery: {},
    normalizeUrl: () => null,
} as SwitchTabContextProps);


function getDefaultTabMetaFilter(tab) {
    const filter = {};
    if (tab.max_days_in_facility) {
        const arrival_date = dayjs().subtract(tab.max_days_in_facility, "day");
        filter["layer_meta.arrival_date_intake_date_from"] = {
            label: `Arrival date > ${arrival_date.format("YYYY-MM-DD")}`,
            field: "layer_meta.arrival_date_intake",
            optional: false,
            method: FILTER_METHODS.DATE_FROM,
            value: arrival_date.format("YYYY-MM-DD"),
        };
    }

    return filter;
}
// Provider component
export const SwitchTabProvider = ({ children }) => {
    const config = useConfig();
    const layers = useSelector<any, any>((state) => state.layers);
    const dispatch = useDispatch();
    const selectedFruitTypes = useFruitTypes();
    const { active_tab } = useParams();
    const navigate = useNavigate();

    const tabs = config.stages.map((i) => ({
        ...i,
        title: i.text,
        subtitle: null,
        active: i.value === active_tab,
        value: i.value,
        layer_type: i.layer_type,
        view_strategy: i.view_strategy,
        default_ordering: i.default_ordering,
        count: i.value === active_tab ? layers.items.count : null,
    }));

    const activeTab = tabs.find((i) => i.active);


    // * Keep track of personal query per tab
    const [personalQueryMap, setPersonalQueryMap] = useState({} as { [stage: string]: any });

    const personalTabQuery = personalQueryMap[active_tab as string] || {};

    const submitTabQuery = useCallback((query) => {
        if (!active_tab) return;
        // * Update the query for the active tab for later usage (when switching tabs)
        setPersonalQueryMap((prev) => ({ ...prev, [active_tab as string]: query }));

        const { ordering, ...new_meta_filter } = query;
        const defaultTabFilter = getDefaultTabMetaFilter(activeTab);

        // * Make sure default filters can onle be overwritten by non-null values
        // * You can edit a default filter, but never completly remove it
        const meta_filter = Object.keys(new_meta_filter).reduce((acc, i) => {
            if (new_meta_filter[i]) {
                acc[i] = new_meta_filter[i];
            }
            return acc;
        }, defaultTabFilter);
        listLayersDebounced({ offset: 0, ordering, meta_filter });
        // * Navigate to the tab without the edit_filter parameter to prevent reopening the tab after a remount
        normalizeUrl();
    }, [active_tab, activeTab?.value]);


    const [listLayersDebounced] = useDebounce(
        (filter, extendFilter = true) => {
            dispatch(updateFilter(filter, extendFilter));
        },
        (filter) => dispatch(listLayers(filter)),
        { debounceTime: 500, immediate: true }
    );


    // * Effect to set the first tab as active tab
    useEffect(() => {
        if (!active_tab && tabs.length > 0) {
            switchTab(tabs[0]);
        }
    }, [active_tab, tabs.length]);

    // * Effect when you change tab or fruit types
    useEffect(() => {
        dispatch(resetLayerList());
        if (activeTab?.value) {
            // * Did the user configured a filter on this tab?
            const query = personalQueryMap[activeTab.value] || {};
            const { ordering, ...userMetaFilter } = query;

            // * Get default meta_filter for this tab
            const defaultTabFilter = getDefaultTabMetaFilter(activeTab);

            let newFilter: LayerFilter = {
                stage: activeTab.filter_stages,
                layer_type: activeTab.layer_type,
                view_strategy: activeTab.view_strategy, // * SELF or AGGREGATE, I think we can drop this...
                offset: 0,
                ordering: ordering || activeTab.default_ordering || "check.created,DESC"
            };

            const fields = getFields(activeTab, selectedFruitTypes);

            // * add select_fields to query
            newFilter = {
                ...newFilter,
                select_fields: getFilledArrayOrDefault(fields).flatMap((i: Field) => i.get_backend_fieldname()),
                meta_filter: { ...defaultTabFilter, ...userMetaFilter },
                fruit_type: selectedFruitTypes,
                offset: 0,
            };
            listLayersDebounced(newFilter, false);
        }
    }, [activeTab?.value, selectedFruitTypes.join(",")]);

    // TODO: loop over each tab and get the count of layers in that tab
    useEffect(() => {
        dispatch(countLayerInStage());
    }, []);


    const getFields = (tab, selectedFruitTypes) => {
        return tab ? applyDisplayFilterLayerFields(tab?.layer_overview_fields || [], {
            fruit_type: getFilledArrayOrDefault(selectedFruitTypes).length === 1 ? selectedFruitTypes[0] : null, // * only enable fruit type filter on fields when exactly one fruit type is selected
            layer_type: tab?.layer_type
        }) : [];
    };

    const fields = useMemo(() => getFields(activeTab, selectedFruitTypes), [activeTab?.value, selectedFruitTypes]);


    const switchTab = (tab) => navigate(`/layer/tab/${tab.value}`);
    const normalizeUrl = useCallback(() => navigate(`/layer/tab/${activeTab?.value}`), [activeTab?.value]);


    return (
        <SwitchTabContext.Provider value={{ switchTab, listLayersDebounced, tabs, activeTab, active_tab, fields, submitTabQuery, personalTabQuery, normalizeUrl }}>
            {children}
        </SwitchTabContext.Provider>
    );
};


// Hook to use the context
export const useSwitchTabContext = () => {
    const context = useContext(SwitchTabContext);
    if (!context) {
        throw new Error("useSwitchTabContext must be used within a SwitchTabProvider");
    }
    return context as SwitchTabContextProps;
};
