/** @jsxImportSource @emotion/react */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";

import { css } from "@emotion/react";
import { faMinus } from "@fortawesome/pro-regular-svg-icons";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { deleteLayerLocationHistory, getLayer } from "../../../../actions/Layers/actions";
import { LAYER_STAGE } from "../../../../actions/Layers/constants";
import useConfig from "../../../../actions/Tenants/config/configHook";
import { Loading } from "../../../Helper/Loading";
import AvosModal from "../../Helper/Modal";

export default function LayerLocationHistory() {
    const locationHistoryLoading = useSelector((state) => state.layers.locationHistoryLoading);
    const locationHistory = useSelector((state) => state.layers.locationHistory);
    const config = useConfig();
    const [modalOpen, setModal] = useState(false);

    const displayStage = (stage) => config.get_stage(stage)?.text;

    useEffect(() => {
        // Close the modal if the latest history item is removed
        if (locationHistory && locationHistory.length === 0) {
            setModal(false);
        }

    }, [locationHistory]);

    if (locationHistoryLoading && !locationHistory) {
        return <Loading />;
    }

    return <div className="pt-4">
        {locationHistory && locationHistory.length > 0 && <div className="pb-5 cursor-pointer" onClick={() => setModal(true)}>
            <h6 className="text-bold">Allocation</h6>
            {locationHistory.map((i, index) => <LocationRow key={index} deleteable={false} location={i} label={displayStage(i.stage)} value={dayjs(i.created).format("DD-MM-YYYY")}></LocationRow>)}
        </div>}

        <AvosModal
            header="Allocations"
            onClose={() => setModal(false)}
            isOpen={modalOpen}
            size="md"
            showSaveButton={false}
        >
            <div>
                {locationHistory.map((i, index) => <div key={index} className="py-2">
                    <LocationRow deleteable={true} location={i} label={displayStage(i.stage)} value={dayjs(i.created).format("DD-MM-YYYY")}></LocationRow>
                </div>)}
            </div>
        </AvosModal>

    </div>;
}

function LocationRow({ label, value, location, deleteable }) {

    const dispatch = useDispatch();
    const element = location.stage === LAYER_STAGE.RIPENING && location.location_id ? <Link to={`/ripening-cell/${location.location_id}`}>{label}</Link> : label;

    return <div className="d-sm-flex align-items-center py-1">
        {deleteable && <div className="pe-2">
            <Button onClick={() => dispatch(deleteLayerLocationHistory(location)).then(() => dispatch(getLayer(location.layer_id)))} color="danger" outline size="sm">
                <FontAwesomeIcon icon={faMinus} />
            </Button>
        </div> }
        <div className="text-muted pe-2" css={css`width: 15rem;`}>
            {element}
        </div>
        <div className="ms-auto text-end">
            {value}
        </div>
    </div>;
}

LocationRow.propTypes = {
    label: PropTypes.string,
    location: PropTypes.object,
    deleteable: PropTypes.bool,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
};
