import { useState } from "react";
import { useSelector } from "react-redux";
import useConfig from "../actions/Tenants/config/configHook";
import { UserPreferences } from "../actions/Users/actions";
import { getFilledArrayOrDefault } from "./getFilledArrayOrDefault";

export const useUserPreferences = () => {
    const userPreferences = useSelector<any, UserPreferences>((state) => state.auth.user.preferences);
    return userPreferences;
};

export const useFruitTypes = () => {
    const [fruitTypes, setFruitTypes] = useState<string[]>([]);
    const config = useConfig();
    const userPreferences = useUserPreferences();
    const allOptions = useSelector((state: any) => state?.tenants?.options_by_field);
    const fruitTypeOptions = allOptions?.[config.tenant_id]?.fruit_type || [];

    // * The preferred fruit types should be available at this point (logged in user)
    // * the fruit type Options come later, we first assume the user list is correct with valid fruit,
    // * then when the fruit options are loaded, we can filter the list to only include valid fruit types
    const preferredFruitTypes = userPreferences?.fruit_types.filter((fruitType) => fruitTypeOptions.find((option) => option.value === fruitType)) || getFilledArrayOrDefault(fruitTypeOptions);

    // TODO: shouldn't this be done in an useEffect?
    // check shallow equality of arrays and update state if needed
    if (preferredFruitTypes.length !== fruitTypes.length || preferredFruitTypes.some((fruitType, index) => fruitType !== fruitTypes[index])) {
        setFruitTypes(preferredFruitTypes);
    }
    return fruitTypes;
};
