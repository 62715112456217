// TODO: refactor this component to use fields api
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Text, View } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
    Table
} from "reactstrap";
import { DEFECT_POSITION, DEFECT_SEVERITY } from "../../../../../actions/Checks/constants";
import { extractMetaProperties } from "../../../../../actions/Layers/util";
import useConfig from "../../../../../actions/Tenants/config/configHook";
import {
    path_atron,
    path_atron_results,
    path_mangos,
    path_manual_check,
    path_manual_or_with_mini,
    path_results_with_form,
    summary_field_atron_weight,
    summary_field_avocado_index,
    summary_field_avos_firmness,
    summary_field_avos_firmness_raw,
    summary_field_box_index,
    summary_field_box_size,
    summary_field_box_weight_empty,
    summary_field_box_weight_gross,
    summary_field_box_weight_net,
    summary_field_brix,
    summary_field_caliber,
    summary_field_color,
    summary_field_colors,
    summary_field_dry_matter,
    summary_field_dry_matter_class,
    summary_field_dry_matter_felix,
    summary_field_durometer_pressures,
    summary_field_external_defects,
    summary_field_first_impression,
    summary_field_flavour_creamy,
    summary_field_flavour_nuttiness,
    summary_field_fruit_diameter,
    summary_field_fruit_firmness_class,
    summary_field_fruit_variety,
    summary_field_gross_weight,
    summary_field_internal_color,
    summary_field_internal_defects,
    summary_field_is_shiny,
    summary_field_lab_check_dm_avg,
    summary_field_major_defects,
    summary_field_manual_quality,
    summary_field_manual_ripening_stage,
    summary_field_maturity,
    summary_field_maturity_string,
    summary_field_minor_defects,
    summary_field_mouth_ripeness,
    summary_field_net_weight,
    summary_field_penetro_pressures,
    summary_field_plu,
    summary_field_predicted_ripening_stage,
    summary_field_pressure,
    summary_field_pressure_classes,
    summary_field_pressure_color_code_by_firmness_class,
    summary_field_pressure_prediction,
    summary_field_ripening_stage_firmness,
    summary_field_taste,
    summary_field_temperature,
    summary_field_texture_consistency,
    summary_field_underweight,
    summary_field_weight,
    summary_field_weight_kg
} from "../../../../../actions/Tenants/config/constants";
import { value_to_category } from "../../../../../actions/Tenants/config/utils";
import { useDefectsHook } from "../../../../Forms/useManagedOptionsHook";
import { ColorStageBadgeConfigurable, DefectBadges, QualityBadge } from "../../../../Helper/Badges";
import { checkUnderweight, toFixed } from "../../../Layers/fields/formatters";
import { AvocadoIndex } from "../Images";

function calculatePercentageOfFruitsWithPLU(fruits) {
    if (fruits.length === 0) {
        return "-";
    }
    const fruitsWithPLU = fruits.filter((fruit) => fruit.plu === "yes");
    const percentage = (fruitsWithPLU.length / fruits.length) * 100;

    return toFixed(percentage, 0, "%");
}


const RESEARCH_MODE_FIELDS = [summary_field_avos_firmness, summary_field_avos_firmness_raw];

export default function FruitTable({ fruits, check, layer, do_print }) {
    const user = useSelector((state) => state.auth.user);
    const config = useConfig();
    const all_defects = useDefectsHook(layer.fruit_type);

    const [header, rows, footer] = getFruitTableData({ layer, check, fruits, config, user, all_defects });

    return <Table size="sm" responsive={!do_print} borderless={true}>
        <thead>
            <tr>{header.map((i, index) => <th key={index} className="text-muted text-start">{i.get_value()}</th>)}</tr>
        </thead>
        <tbody>
            {rows.map((i, index) => <tr key={index}>
                {i.map((x, xindex) => <td key={xindex} className="text-capitalize text-start align-middle" >{x.get_value()}</td>)}
            </tr>)}
        </tbody>
        <tbody>
            <tr>
                {footer.map((i, index) => <td key={index} className="fw-bold text-start ">{i.get_value()}</td>)}
            </tr>
        </tbody>
    </Table >;
}

FruitTable.defaultProps = {
    do_print: false
};
FruitTable.propTypes = {
    check: PropTypes.object,
    layer: PropTypes.object,
    do_print: PropTypes.bool,
    fruits: PropTypes.array
};


const DefectBadgesVector = ({ defects }) => {
    if (defects.length === 0) {
        return <Text style={{ fontSize: "9px" }}>-</Text>;
    }
    return <View style={{ display: "flex", maxWidth: "300px", flexWrap: "nowrap", flexDirection: "row" }}>
        {defects.map((i) => <DefectBadgeVector key={i.defect_id} defect={i} />)}
    </View>;
};
DefectBadgesVector.propTypes = {
    defects: PropTypes.array
};

export const textFlagColors = {
    success: "#13a55f",
    danger: "#dc3545",
    warning: "#ffc107",
};

export function ColorCodedSpan({ children, flag }) {
    const className = textFlagColors[flag] ? `text-${flag}` : "";
    return <span className={className}>{children}</span>;
}

ColorCodedSpan.propTypes = {
    children: PropTypes.node,
    flag: PropTypes.string,
};

const ColorCodedTextVector = ({ children, flag }) => {
    const color = textFlagColors[flag] ? { color: textFlagColors[flag] } : {};
    return <Text style={{ fontSize: "9px", display: "flex", flexWrap: "wrap", maxWidth: "150px", ...color }}>{children}</Text>;
};

ColorCodedTextVector.propTypes = {
    children: PropTypes.node,
    flag: PropTypes.string,
};

const DefectBadgeVector = ({ defect }) => {

    return (
        <View style={{ backgroundColor: "#f0f0f0", padding: "1px 3px", borderRadius: "10px", margin: "0px 2px 0px 0px", textOverflow: "", overflow: "hidden" }}>
            <Text style={{ fontSize: "7px", textOverflow: "", padding: "0px 2px" }}>{defect.label}</Text>
        </View>
    );

};

DefectBadgeVector.propTypes = {
    defect: PropTypes.object,
};


class FruitTableField {
    component = false;

    vector = false;

    constructor(value = "", component, vector) {
        this.value = value;
        if (component) {
            this.component = component;
        }
        if (vector) {
            this.vector = vector;
        }
    }

    get_raw() {
        return this.value;
    }

    get_value() {
        return this.component || this.value;
    }

    get_vector() {
        return this.vector || <Text style={{ fontSize: "9px", display: "flex", flexWrap: "wrap", maxWidth: "150px" }}>{this.value}</Text>;
    }

}

const aggregate = (fruits) => {
    const average_keys = ["pressure", "pressure_1", "pressure_2", "atron_pressure_kg", "atron_pressures_median", "manual_dry_matter",
        "summary_field_penetro_pressures", "manual_maturity", "manual_brix", "manual_internal_color_stage", "manual_temperature", "weight",
        "fruit_diameter", "box_weight_net"];
    const sum = {};
    const count = {};
    average_keys.forEach((i) => {
        sum[i] = 0;
        count[i] = 0;
    });

    fruits.forEach((a) => {
        average_keys.forEach((i) => {
            // If we are trying to sum atron_pressure_kg, we give preference to actual pressure
            if (i === "atron_pressure_kg" && a.pressure !== undefined && a.pressure !== null && a.pressure !== 0 && a.pressure !== "") {
                sum[i] += parseFloat(a.pressure);
                count[i]++;
            } else if (a[i] !== undefined && a[i] !== null && a[i] !== "") {
                sum[i] += parseFloat(a[i]);
                count[i]++;
            }
        });
    });

    const average = {};
    average_keys.forEach((i) => {
        if (count[i] > 0) {
            average[i] = sum[i] / count[i];
        } else {
            average[i] = false; // To avoid NaN for keys with no valid values
        }
    });

    return {
        sum,
        average,
        count
    };
};

export function getPathForEditFruit(flow) {
    const possible_fruit_paths = [
        path_manual_or_with_mini,
        path_manual_check,
        path_results_with_form,
        path_atron_results,
        path_atron,
        path_mangos,
    ];
    return flow?.find((i) => possible_fruit_paths.includes(i));

}


// ulgly hardcoded hack.... when will we refactor this madness?
function pressureColorClass(a) {
    if (a?.flag_client_spec && a.flag_client_spec !== "light") {
        return `text-${a.flag_client_spec}`;
    }
    if (a?.flag_pressure_movement && a.flag_pressure_movement !== "light") {
        return `text-${a.flag_pressure_movement}`;
    }
    if (a?.flag_pressure && a.flag_pressure !== "light") {
        return `text-${a.flag_pressure}`;
    }
    return "";
}

// ulgly hardcoded hack.... when will we refactor this madness?
function weightColorClass(a) {
    if (a?.flag_underweight && a.flag_underweight !== "light") {
        return `text-${a.flag_underweight}`;
    }
    return "";
}
const getRegisteredDefectList = (all_defects, defect_position, defect_severity, fruit_defects) => {
    const filtered_defects = all_defects.filter((i) => defect_position.includes(i.position) && defect_severity.includes(i.severity));

    const registered_defects = filtered_defects.filter((i) => {
        const registered_defect = fruit_defects.find((j) => j.defect_id === i.defect_id);
        return registered_defect?.frequency > 0;
    });
    return new FruitTableField(
        registered_defects.map((i) => i.label).join(", "),
        <div css={css`max-width: 12rem;`}>
            <DefectBadges defects={registered_defects} />
        </div>,
        <DefectBadgesVector defects={registered_defects} />

    );
};

export function getFruitTableData({ layer, check, fruits, config, user, all_defects }) {
    const palletFruitType = layer.fruit_type || "fruit";
    const location = config.get_location(layer, check);
    const fruit_path = getPathForEditFruit(location?.flow);
    const fruitConfig = config.get_fruit_type(palletFruitType);


    // TODO: deprecate this research mode stuff in favour of new wizard
    const filtered_summary_fields = (location?.summary_fruit_fields || []).filter((i) => config.research_mode || !RESEARCH_MODE_FIELDS.includes(i));

    const { average } = aggregate(fruits);

    const rows = extractMetaProperties(fruits).map((a, index) => filtered_summary_fields.map((i) => {
        const metric = user.tenant.uses_kg ? "kg" : "lbs";
        switch (i) {
        case summary_field_avocado_index:
            return new FruitTableField(
                index + 1,
                <AvocadoIndex avocado={a} key={a.avocado_id} index={index + 1} fruit_path={fruit_path} />
            );
        case summary_field_box_index:
            return new FruitTableField(
                index + 1,
                <AvocadoIndex avocado={a} key={a.avocado_id} index={index + 1} fruit_path={fruit_path} />
            );
        case summary_field_fruit_variety:
            return new FruitTableField(
                layer?.fruit_variety,
                <span className="text-capitalize">{layer?.fruit_variety}</span>
            );
        case summary_field_box_size:
            return new FruitTableField(
                layer?.box_size ? layer.box_size : "-"
            );
        case summary_field_avos_firmness:
            return new FruitTableField(
                config.firmness_to_display_value(a.atron_pressures_median)
            );
        case summary_field_avos_firmness_raw:
            return new FruitTableField(
                a.atron_pressures.map((i) => config.firmness_to_display_value(i)).join(", ")
            );
        case summary_field_pressure:
            if (user.tenant.has_two_fta) {
                return new FruitTableField(
                    `${toFixed(a.pressure_1, 2, metric)} - ${toFixed(a.pressure_2, 2, metric)}`,
                    <span className={`fw-bold ${pressureColorClass(a)}`} >{`${toFixed(a.pressure_1, 2, metric)} - ${toFixed(a.pressure_2, 2, metric)}`}</span>,
                    <ColorCodedTextVector flag={a.flag_client_spec || a.flag_pressure_movement || a.flag_pressure}>{`${toFixed(a.pressure_1, 2, metric)} - ${toFixed(a.pressure_2, 2, metric)}`}</ColorCodedTextVector>
                );
            }
            return new FruitTableField(
                toFixed(a.pressure, 2, metric),
                <span className={`fw-bold ${pressureColorClass(a)}`} >{toFixed(a.pressure, 2, metric)}</span>,
                <ColorCodedTextVector flag={a.flag_client_spec || a.flag_pressure_movement}>{toFixed(a.pressure, 2, metric)}</ColorCodedTextVector>
            );
        case summary_field_pressure_prediction:
            if (a.pressure) {
                return new FruitTableField(
                    toFixed(a.pressure, 2, metric),
                    <span className={`fw-bold ${pressureColorClass(a)}`} >{toFixed(a.pressure, 2, metric)}</span>,
                    <ColorCodedTextVector flag={a.flag_client_spec || a.flag_pressure_movement}>{toFixed(a.pressure, 2, metric)}</ColorCodedTextVector>
                );
            }
            return new FruitTableField(
                toFixed(a.atron_pressure_kg, 2, metric),
                <span className={`${pressureColorClass(a)}`} >{toFixed(a.atron_pressure_kg, 2, metric)}</span>,
                <ColorCodedTextVector flag={a.flag_client_spec || a.flag_pressure_movement}>{toFixed(a.atron_pressure_kg, 2, metric)}</ColorCodedTextVector>
            );

            // this is easier done in a wizard...
        case summary_field_external_defects:
            return getRegisteredDefectList(all_defects, [DEFECT_POSITION.EXTERNAL], Object.values(DEFECT_SEVERITY), a.defects);

        case summary_field_minor_defects:
            return getRegisteredDefectList(all_defects, Object.values(DEFECT_POSITION), [DEFECT_SEVERITY.MINOR], a.defects);

        case summary_field_internal_defects:
            return getRegisteredDefectList(all_defects, [DEFECT_POSITION.INTERNAL], Object.values(DEFECT_SEVERITY), a.defects);

        case summary_field_major_defects:
            return getRegisteredDefectList(all_defects, Object.values(DEFECT_POSITION), [DEFECT_SEVERITY.MAJOR], a.defects);


            // TODO: I disable the color badge thing for now.
        case summary_field_color:
            return new FruitTableField(
                a.manual_color_stage,
                <ColorStageBadgeConfigurable color_stage={a.manual_color_stage} colors={config.get_fruit_type_external_colors(layer)} />
            );
        case summary_field_internal_color:
            return new FruitTableField(
                a.manual_internal_color_stage,
                <ColorStageBadgeConfigurable color_stage={a.manual_internal_color_stage} colors={config.get_fruit_type_internal_colors(layer)} />
            );
        case summary_field_brix:
            return new FruitTableField(
                toFixed(a.manual_brix, 0, "%"),
                <ColorCodedSpan flag={a.flag_brix_spec}>{toFixed(a.manual_brix, 0, "%")}</ColorCodedSpan>,
                <ColorCodedTextVector flag={a.flag_brix_spec}>{toFixed(a.manual_brix, 0, "%")}</ColorCodedTextVector>
            );

        case summary_field_fruit_diameter:
            return new FruitTableField(
                a.fruit_diameter ? `${a.fruit_diameter}mm` : "-"
            );

        case summary_field_dry_matter:
            return new FruitTableField(
                toFixed(a.manual_dry_matter, 0, "%"),
                <ColorCodedSpan flag={a.flag_manual_dry_matter}>{toFixed(a.manual_dry_matter, 0, "%")}</ColorCodedSpan>,
                <ColorCodedTextVector flag={a.flag_manual_dry_matter}>{toFixed(a.manual_dry_matter, 0, "%")}</ColorCodedTextVector>
            );
        case summary_field_dry_matter_felix:
            return new FruitTableField(
                a.manual_dry_matter_felix ? `${a.manual_dry_matter_felix} %` : "-"
            );
        case summary_field_lab_check_dm_avg:
            return new FruitTableField(
                a.manual_dry_matter,
                <span className={`${a.manual_dry_matter <= 21 ? "text-danger" : "text-start "}`} > {a.manual_dry_matter ? `${toFixed(a.manual_dry_matter, 1)} %` : "-"} </span>
            );
        case summary_field_dry_matter_class:
            return new FruitTableField(
                value_to_category(a.manual_dry_matter, config.dry_matter_classes).label
            );
        case summary_field_temperature:
            return new FruitTableField(
                a.manual_temperature ? `${a.manual_temperature} °C` : "-"
            );
        case summary_field_maturity:
            return new FruitTableField(
                a.manual_maturity ? a.manual_maturity : "-"
            );
        case summary_field_taste:
            return new FruitTableField(
                a.manual_taste ? a.manual_taste : "-"
            );
        case summary_field_predicted_ripening_stage:
            return new FruitTableField(
                config.kg_to_ripening_stage(a.pressure ? a.pressure : a.atron_pressure_kg).label
            );
        case summary_field_ripening_stage_firmness:
            return new FruitTableField(
                config.firmness_to_ripening_stage(a.atron_pressures_median).label
            );
        case summary_field_fruit_firmness_class: {
            return new FruitTableField(
                value_to_category(a.atron_pressures_median, fruitConfig.firmness_class).label
            );
        }

        case summary_field_pressure_color_code_by_firmness_class: {

            const firmness_label = config.firmness_to_ripening_stage(a.atron_pressures_median).label.substring(0, 3);
            const pressure_label = config.kg_to_ripening_stage(a.pressure).label.substring(0, 3);
            const text_color = firmness_label !== pressure_label ? "text-danger" : "";

            if (user.tenant.has_two_fta) {
                const pressure = (
                    `${toFixed(a.pressure_1, 2, metric)} - ${toFixed(a.pressure_2, 2, metric)}`
                );
                return new FruitTableField(
                    pressure,
                    <span title={`${firmness_label} == ${pressure_label}`} className={`text-start ${text_color}`} >{toFixed(a.pressure, 2, metric)}</span>
                );
            }
            return new FruitTableField(
                toFixed(a.pressure, 2, metric),
                <span title={`${firmness_label} == ${pressure_label}`} className={`text-start ${text_color}`} > {toFixed(a.pressure, 2, metric)}</span>
            );

        }

        case summary_field_manual_ripening_stage:
            return new FruitTableField(
                config.kg_to_ripening_stage(a.pressure).label
            );
        case summary_field_manual_quality:
            return new FruitTableField(
                a.manual_quality,
                <QualityBadge quality={a.manual_quality} />
            );
        case summary_field_is_shiny:
            return new FruitTableField(
                a.is_shiny || "-"
            );

        case summary_field_atron_weight:
            return new FruitTableField(
                a.atron_weight_gram ? `${a.atron_weight_gram} GRAM` : "-"
            );

        case summary_field_weight:
            return new FruitTableField(
                toFixed(a.weight, 0),
                <span className={`${weightColorClass(a)}`} >{toFixed(a.weight, 0)}</span>,
                <ColorCodedTextVector flag={a.flag_underweight}>{toFixed(a.weight, 0)}</ColorCodedTextVector>
            );

        case summary_field_weight_kg:
            return new FruitTableField(
                toFixed(a.weight, 0, "KG")
            );

        case summary_field_penetro_pressures:
            return new FruitTableField(
                a.pressure_1 && a.pressure_2 ? `${toFixed(a.pressure_1, 2, metric)} - ${toFixed(a.pressure_2, 2, metric)}` : "-",
            );

        case summary_field_durometer_pressures:
            return new FruitTableField(
                a.durometer_pressure_1 && a.durometer_pressure_2 ? `${toFixed(a.durometer_pressure_1, 2, "kg")} - ${toFixed(a.durometer_pressure_2, 2, "kg")}` : "-",
            );

        case summary_field_pressure_classes:
            return new FruitTableField(
                value_to_category(a.pressure, config.ripening_stages_pressure).label
            );
        case summary_field_plu:
            return new FruitTableField(
                a.plu ? a.plu : "-"
            );
        case summary_field_mouth_ripeness:
            return new FruitTableField(
                a.mouth_ripeness ? a.mouth_ripeness : "-"
            );
        case summary_field_flavour_nuttiness:
            return new FruitTableField(
                a.flavour_nuttiness ? a.flavour_nuttiness : "-"
            );
        case summary_field_flavour_creamy:
            return new FruitTableField(
                a.flavour_creamy ? a.flavour_creamy : "-"
            );

        case summary_field_gross_weight:
            return new FruitTableField(toFixed(a.gross_weight, 0, "GR"));
        case summary_field_net_weight:
            return new FruitTableField(toFixed(a.net_weight, 0, "GR"));
        case summary_field_caliber:
            return new FruitTableField(a.caliber, 0, "mm");

            // remove this and just use weight and set flag with internal data processing
        case summary_field_underweight:
            return new FruitTableField(
                checkUnderweight(toFixed(a.weight, 0), layer.box_size, config?.underweight)
            );

        case summary_field_first_impression:
            return new FruitTableField(a.first_impression || "-");
        case summary_field_texture_consistency:
            return new FruitTableField(a.texture_consistency || "-");
        case summary_field_colors:
            return new FruitTableField(a.color || "-");
        case summary_field_maturity_string:
            return new FruitTableField(a.manual_maturity_string || "-");
        case summary_field_box_weight_empty:
            return new FruitTableField(a.box_weight_empty || "-");
        case summary_field_box_weight_gross:
            return new FruitTableField(a.box_weight_gross || "-");
        case summary_field_box_weight_net: {
            return new FruitTableField(a.box_weight_net || "-");
        }

        default:
            return new FruitTableField("Unknown field");
        }

    }));


    const headers = filtered_summary_fields.map((i) => {
        switch (i) {
        case summary_field_avocado_index:
            return new FruitTableField("Fruit sample");
        case summary_field_box_index:
            return new FruitTableField("Box sample");
        case summary_field_fruit_variety:
            return new FruitTableField("Variety");
        case summary_field_avos_firmness:
            return new FruitTableField("Firmness");
        case summary_field_avos_firmness_raw:
            return new FruitTableField("Raw firmness");
        case summary_field_pressure_color_code_by_firmness_class:
            return new FruitTableField(config.lang.pressure);
        case summary_field_pressure:
            return new FruitTableField(config.lang.pressure);
        case summary_field_pressure_prediction:
            return new FruitTableField("Pressure");
        case summary_field_external_defects:
            return new FruitTableField("Ext. def");
        case summary_field_internal_defects:
            return new FruitTableField("Int. def");
        case summary_field_minor_defects:
            return new FruitTableField("Minor def");
        case summary_field_major_defects:
            return new FruitTableField("Major def");
        case summary_field_color:
            return new FruitTableField("Color");
        case summary_field_internal_color:
            return new FruitTableField("Internal color");
        case summary_field_weight:
            return new FruitTableField("Weight (gram)");
        case summary_field_weight_kg:
            return new FruitTableField("Weight");
        case summary_field_brix:
            return new FruitTableField("Brix");
        case summary_field_fruit_diameter:
            return new FruitTableField("Diameter");
        case summary_field_maturity:
            return new FruitTableField("Maturity");
        case summary_field_taste:
            return new FruitTableField("Taste");
        case summary_field_temperature:
            return new FruitTableField("Pulp temp");
        case summary_field_dry_matter:
            return new FruitTableField("Dry matter");
        case summary_field_dry_matter_felix:
            return new FruitTableField("Dry matter felix");
        case summary_field_lab_check_dm_avg:
            return new FruitTableField("Dry matter");
        case summary_field_dry_matter_class:
            return new FruitTableField("Dry matter class");
        case summary_field_predicted_ripening_stage:
            return new FruitTableField("Ripening class");
        case summary_field_ripening_stage_firmness:
            return new FruitTableField("Firmness class");
        case summary_field_fruit_firmness_class:
            return new FruitTableField("Firmness class");
        case summary_field_manual_ripening_stage:
            return new FruitTableField("Ripening class");
        case summary_field_manual_quality:
            return new FruitTableField("Quality");
        case summary_field_is_shiny:
            return new FruitTableField("Shiny/Matte");
        case summary_field_atron_weight:
            return new FruitTableField("Weight");
        case summary_field_penetro_pressures:
            return new FruitTableField("Penetrometer");
        case summary_field_durometer_pressures:
            return new FruitTableField("Durometer");
        case summary_field_pressure_classes:
            return new FruitTableField("Pressure class");
        case summary_field_plu:
            return new FruitTableField("PLU");
        case summary_field_box_size:
            return new FruitTableField("Size");
        case summary_field_underweight:
            return new FruitTableField("Underweight");
        case summary_field_mouth_ripeness:
            return new FruitTableField("Mouth ripeness");
        case summary_field_flavour_nuttiness:
            return new FruitTableField("Flavour nuttiness");
        case summary_field_flavour_creamy:
            return new FruitTableField("Flavour creamy");

        case summary_field_gross_weight:
            return new FruitTableField("Gross weight");
        case summary_field_net_weight:
            return new FruitTableField("Net weight");
        case summary_field_caliber:
            return new FruitTableField("Caliber");

        case summary_field_first_impression:
            return new FruitTableField("First impression");
        case summary_field_texture_consistency:
            return new FruitTableField("Texture consistency");
        case summary_field_colors:
            return new FruitTableField("Color");
        case summary_field_maturity_string:
            return new FruitTableField("Maturity");

        case summary_field_box_weight_empty:
            return new FruitTableField("Box weight empty");

        case summary_field_box_weight_gross:
            return new FruitTableField("Box weight gross");

        case summary_field_box_weight_net:
            return new FruitTableField("Box weight net");

        default:
            return new FruitTableField("Unknown field");
        }
    });


    const footers = filtered_summary_fields.map((i) => {
        const metric = user.tenant.uses_kg ? "kg" : "lbs";

        switch (i) {
        case summary_field_avocado_index:
            return new FruitTableField();
        case summary_field_box_index:
            return new FruitTableField();
        case summary_field_fruit_variety:
            return new FruitTableField();
        case summary_field_avos_firmness:
            return new FruitTableField();
        case summary_field_avos_firmness_raw:
            return new FruitTableField();
        case summary_field_pressure_color_code_by_firmness_class:
        case summary_field_pressure:
            return new FruitTableField(toFixed(average.pressure, 2, metric));
        case summary_field_pressure_prediction:
            return new FruitTableField(toFixed(average.atron_pressure_kg, 2, metric));
        case summary_field_external_defects:
            return new FruitTableField();
        case summary_field_internal_defects:
            return new FruitTableField();
        case summary_field_minor_defects:
            return new FruitTableField();
        case summary_field_major_defects:
            return new FruitTableField();
        case summary_field_color:
            return new FruitTableField();
        case summary_field_internal_color:
            return new FruitTableField(toFixed(average.manual_internal_color_stage, 1, ""));
        case summary_field_weight:
            return new FruitTableField(toFixed(average.weight, 1));
        case summary_field_weight_kg:
            return new FruitTableField();
        case summary_field_brix:
            return new FruitTableField(toFixed(average.manual_brix, 0, "%"));
        case summary_field_fruit_diameter:
            return new FruitTableField(toFixed(average.fruit_diameter, 1, "mm"));
        case summary_field_dry_matter:
            return new FruitTableField();
        case summary_field_dry_matter_felix:
            return new FruitTableField();
        case summary_field_lab_check_dm_avg:
            return new FruitTableField(average.manual_dry_matter, <span className={`${average.manual_dry_matter <= 21 ? "text-danger" : "text-start "}`} > {average.manual_dry_matter ? `${toFixed(average.manual_dry_matter)} %` : "-"} </span>);
        case summary_field_dry_matter_class:
            return new FruitTableField(average.manual_dry_matter ? value_to_category(average.manual_dry_matter, config.dry_matter_classes).label : "-");
        case summary_field_ripening_stage_firmness:
            return new FruitTableField(average.atron_pressures_median ? config.firmness_to_ripening_stage(average.atron_pressures_median).label : "-");
        case summary_field_fruit_firmness_class:
            return new FruitTableField(average.atron_pressures_median ? value_to_category(average.atron_pressures_median, fruitConfig.firmness_class).label : "-");
        case summary_field_manual_ripening_stage:
            return new FruitTableField(average.pressure ? config.kg_to_ripening_stage(average.pressure).label : "-");
        case summary_field_predicted_ripening_stage:
            return new FruitTableField(average.atron_pressure_kg ? config.kg_to_ripening_stage(average.atron_pressure_kg).label : "-");
        case summary_field_pressure_classes:
            return new FruitTableField(average.pressure ? value_to_category(average.pressure, config.ripening_stages_pressure).label : "-");
        case summary_field_maturity:
            return new FruitTableField(toFixed(average.manual_maturity, 1));
        case summary_field_manual_quality:
            return new FruitTableField();
        case summary_field_is_shiny:
            return new FruitTableField();
        case summary_field_temperature:
            return new FruitTableField(toFixed(average.manual_temperature, 2, "°C"));
        case summary_field_taste:
            return new FruitTableField();
        case summary_field_atron_weight:
            return new FruitTableField();
        case summary_field_penetro_pressures:
            return new FruitTableField();
        case summary_field_durometer_pressures:
            return new FruitTableField();
        case summary_field_plu:
            return new FruitTableField(calculatePercentageOfFruitsWithPLU(fruits));
        case summary_field_box_size:
            return new FruitTableField();
        case summary_field_underweight:
        case summary_field_mouth_ripeness:
            return new FruitTableField();
        case summary_field_flavour_nuttiness:
            return new FruitTableField();
        case summary_field_flavour_creamy:
            return new FruitTableField();

        case summary_field_gross_weight:
            return new FruitTableField();
        case summary_field_net_weight:
            return new FruitTableField();
        case summary_field_caliber:
            return new FruitTableField();
        case summary_field_first_impression:
            return new FruitTableField();
        case summary_field_texture_consistency:
            return new FruitTableField();
        case summary_field_colors:
            return new FruitTableField();
        case summary_field_maturity_string:
            return new FruitTableField();
        case summary_field_box_weight_empty:
            return new FruitTableField();
        case summary_field_box_weight_gross:
            return new FruitTableField();
        case summary_field_box_weight_net:
            return new FruitTableField(toFixed(average.box_weight_net, 0, ""));

        default:
            return new FruitTableField("Unknown field");
        }
    });
    return [headers, rows, footers];
}

