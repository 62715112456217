import dayjs from "dayjs";


export function toFixed(value: any, decimals = 2, unity = "", value_if_null = "-"): string {
    if (value === "-") {
        return value_if_null;
    }
    return value || value === 0 ? parseFloat(value).toFixed(decimals) + unity : value_if_null;
}

export function formatDate(ts: any, template = "DD-MM-YYYY", value_if_null = "-") {
    return formatDateTime(ts, template, value_if_null);
}

export function formatDateTime(ts: any, template = "HH:mm DD-MM-YYYY", value_if_null = "-") {
    if (!ts || ts === "None") { return value_if_null; }
    if (ts === value_if_null) { return value_if_null; }

    // Ugly code alert !! - Discuss with team, we are going to refactor this
    // * check if date is in ddmmyyyy format, if so convert it to yyyy-mm-dd
    const ddmmyyyyRegex = /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-(\d{4})$/;
    let formattedDate = ts;
    if (ddmmyyyyRegex.test(ts)) {
        const [day, month, year] = ts.split("-");
        formattedDate = `${year}-${month}-${day}`;
    }

    const output = dayjs(formattedDate).utcOffset(0, true);

    if (!output.isValid()) {
        return value_if_null;
    }

    return output.format(template);
}

export function convert_flag_state_color(state: any): string {
    if (state) {
        const output = state.split("flag-").pop();
        return output;
    }
    return "light";
}


export function toPercentage(numerator: any, denominator: number | null | undefined, decimals = 0, value_if_null = "-"): string {
    if (numerator === value_if_null) {
        return value_if_null;
    }
    if (denominator && denominator > 0) {
        numerator = numerator || 0;
        return toFixed(round((numerator / denominator) * 100, decimals), decimals, "%");
    }

    return value_if_null;
}

export function toFraction(count: number, total: number | null | undefined, value_if_null = "-"): string {

    let status = value_if_null;

    if (count > 0) {
        total = total || 1;
        status = `${count}/${total}`;
    }

    return status;
}

export function round(value: number, decimals: number) {
    const multiplier = 10 ** (decimals || 0);
    return Math.round(value * multiplier) / multiplier;
}


//  remove this function just use weight field in fruit table and use internal data processing to set the flag
export function checkUnderweight(weight: string, box_size: string, min_weight: Array<{ box_size: number; weight: number; }>): string {
    const int_weight = parseInt(weight, 10);
    const int_box_size = parseInt(box_size, 10);
    const minWeightForBox = min_weight.find((item) => item.box_size === int_box_size)?.weight;

    if (minWeightForBox === undefined || !int_weight || !int_box_size || !min_weight) {
        return "-";
    }

    if (int_weight < minWeightForBox) {
        return "Yes";
    } return "No";
}

// * metric are stored as values between 0 and 1, this function converts it to a percentage
export const metricPercentage = (value, decimals, value_if_null) => toFixed(value * 100, decimals, "%", value_if_null);

export const FORMATTERS = {
    toFixed,
    formatDate,
    formatDateTime,
    metricPercentage,
    // toFraction,
};

// * this function setups a callback that can be used as value filter for FIELDS api
// * we could in theory also setup other perecentages here. but we should enforce business consulatant to use internal data processing to actually store the perccentage in the DB,
// * as that will also make it posibble to filter / order on those fields
export const setupFormatter = (formatter_key, { decimals, unity, value_if_null, datetime_format }) => {
    switch (formatter_key) {
    case "toFixed":
        return (value) => toFixed(value, decimals, unity, value_if_null);
    case "formatDate":
        return (value) => formatDate(value, datetime_format || "DD-MM-YYYY", value_if_null);
    case "formatDateTime":
        return (value) => formatDateTime(value, datetime_format || "HH:mm DD-MM-YYYY", value_if_null);
    case "metricPercentage":
        return (value) => metricPercentage(value, decimals, value_if_null);
        // cannot do this with a formatter, as it requires to read another field from unkown object
        // case "toFraction":
        //     return (value, object) => toFraction(value, object?.[denominator], value_if_null);
    default:
        return (value) => value;
    }

};

