/** @jsxImportSource @emotion/react */
import { Link } from "react-router-dom";
import useConfig from "../../../../actions/Tenants/config/configHook";
import { headerLinks } from "../../../../actions/Tenants/config/constants";
import MetaForm from "../../../Forms/MetaForm";
import FieldConfigForm from "./FieldConfigForm";
import FormConfigForm from "./FormConfigForm";
import NavigationConfigForm from "./NavigationConfigForm";
import RipeWiseMetricCardsConfigForm from "./RipeWiseMetricCardsConfigForm";

export const defaultRootConfig = {
    text: "root",
    value: "root",
    primary_header_links: [
        { path: headerLinks.home, label: "Overview" }
    ],
    secondary_header_links: [
        { path: headerLinks.settings, label: "Settings" },
        { path: headerLinks.logout, label: "Logout" },
    ],
    overview_general_filter_form: []
};


export default function RootConfigForm({ config_data, setConfigData }) {
    const config = useConfig();
    const { tenant_id } = useConfig();

    const form = [

        {
            label: "Value",
            description: "What is the slug or identifier of this layer type. Used in the database and in urls.",
            name: "value",
            type: "single-select",
            readOnly: true,
            default: "root",
            options: [{ value: "root", label: "root" }]
        },
        {
            type: "element",
            el: <NavigationConfigForm
                name="primary_header_links"
                title="Primary header links"
                description="Primary navigation links in the header"
                fields={config_data.primary_header_links}
                setArray={(value) => setConfigData({ ...config_data, primary_header_links: value })}
            />
        },
        {
            type: "element",
            el: <NavigationConfigForm
                name="secondary_header_links"
                title="Secondary header links"
                description="Secondary navigation links in the header"
                fields={config_data.secondary_header_links}
                setArray={(value) => setConfigData({ ...config_data, secondary_header_links: value })}
            />
        },
        {
            type: "element",
            el: <FormConfigForm
                fields={config_data.overview_general_filter_form || []}
                description="Specify the fields will generate the general layer filter in the overview screen."
                name="overview_general_filter_form"
                title="General Filter"
                setArray={(overview_general_filter_form) => setConfigData({ ...config_data, overview_general_filter_form })} />
        },
        {
            type: "header",
            label: "RipeWise Config"
        },
        {
            type: "element",
            el: <FieldConfigForm
                fields={config_data.ripening_cell_overview_fields || []}
                description={<span>What fields to display on the ripening cell overview screen (<Link to={`/tenants/${tenant_id}/facility-locations`}>list of cells</Link>).</span>}
                useDisplayFilter={["fruit_types", "exclude_fruit_types"]}
                name="ripening_cell_overview_fields"
                title="Ripening Cell Overview"
                setArray={(ripening_cell_overview_fields) => setConfigData({ ...config_data, ripening_cell_overview_fields })} />
        },
        {
            type: "element",
            el: <FieldConfigForm
                fields={config_data.ripening_cell_pallet_fields || []}
                description="What fields to display on the ripening cell screen (list of allocated pallets)."
                useDisplayFilter={["fruit_types", "exclude_fruit_types"]}
                name="ripening_cell_pallet_fields"
                title="Ripening Cell Overview"
                setArray={(ripening_cell_pallet_fields) => setConfigData({ ...config_data, ripening_cell_pallet_fields })} />
        },
        {
            type: "element",
            el: <RipeWiseMetricCardsConfigForm
                fields={config_data.ripewise_metric_cards || []}
                description="Show metric cards on the ripening cell screen."
                name="ripewise_metric_cards"
                title="Ripening Cell Metrics"
                setArray={(ripewise_metric_cards) => setConfigData({ ...config_data, ripewise_metric_cards })} />

        }
    ];

    return <MetaForm meta={form} setValue={(field, value) => setConfigData({ ...config_data, [field]: value })} object={config_data} config={config} />;
}
