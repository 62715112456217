/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";


export function TableHeaderCell({ children, className = "text-muted align-middle text-start text-nowrap fw-bold py-1 px-2 " }: { children?: React.ReactNode, className?: string }) {
    return <td className={className} css={css`color: black !important;background: #e5e8e5!important; border-right: 1px solid white;`}>
        {children}
    </td>;
}


export function TableCell({ children, className = "text-start align-middle text-nowrap px-2 text-capitalize" }: { children?: React.ReactNode, className?: string }) {
    return <td className={className}>{children}</td>;
}

