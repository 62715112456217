/** @jsxImportSource @emotion/react */
import { FILTER_METHOD_TYPES } from "../../../../actions/Layers/actions";
import { Check, Fruit, Layer, RipeningCell } from "../../../../actions/Layers/constants";
import { FormOption, RootConfigType } from "../../../../actions/Tenants/config/constantsTyped";
import { capitalizeFirstLetter } from "../../../../utils/tools";
// import {ColorCodedTextVector} from "frontend/src/components/scenes/Layers/Layer/Report/PDFCheckChildrenDefects.tsx"
import { ColorCodedTextVector } from "../Layer/Report/PDFCheckChildrenDefects";
import { truncateText } from "../Layer/Report/utils";

export const SORTING_BACKENDS = ["layer_meta", "layer", "check", "latest_location"];
// export const FILTER_BACKENDS = ["layer_meta"];

export interface DataSource {
    config: RootConfigType;
    layer: Layer;
    check: Check | null; // * To be used in check tables and layer overview
    fruit: Fruit | null; // * To be used in fruit tables
    // aggregated_fruit: AggregatedFruit | null; // * To be used in  check tables and layer overview
    children?: {
        results: Layer[]
        count: number
    } | null; // * To be used in layer overview
    form_options: { [key: string]: FormOption[] };
    ripeningCell?: RipeningCell,
}


interface FieldOptions {
    fieldname_getter?: string;
    fieldname_setter?: string;
    formatters?: ((value: string | number | null | undefined) => string)[];
    string_when_empty?: string;
    append_key?: string;
    color_code_field?: string | false;
    value_filter?: (value: any, _datasource: DataSource) => any; // Note the underscore before datasource
}

class Field {
    field_type = "general_field";

    backend_table = "layer";

    fieldname_getter: string;

    fieldname_setter: string;

    // reference field that contains the color code for this field
    color_code_field?: string | false;

    label: string;

    append_key: string; // TODO: not needed anymore

    value: any = null;

    string_when_empty = "-";

    flag_class = "";

    flag_color = "";

    classname = "";

    filter_config: {
        formatter: (value: string | number | null) => string;
        decimals?: number;
        unity?: string;
        value_if_null?: string;
        datetime_format?: string;
        filter_method_type: string;
        min?: number;
        max?: number;

    } = {
            formatter: (value: string | number | null) => value as string,
            filter_method_type: FILTER_METHOD_TYPES.TEXT,
        };

    formatters: ((value: string | number | null) => string)[];

    value_filter: (value: any, _datasource: DataSource) => any;

    constructor(
        fieldname: string,
        label: string,
        options: FieldOptions = {}
    ) {
        const {
            fieldname_getter = fieldname,
            fieldname_setter = fieldname,
            formatters = [],
            string_when_empty = "-",
            append_key = "",
            color_code_field = false,
            value_filter = (value: any, _: DataSource) => value // eslint-disable-line @typescript-eslint/no-unused-vars
        } = options;

        this.fieldname_getter = fieldname_getter;
        this.fieldname_setter = fieldname_setter;
        this.label = label;
        this.formatters = formatters;
        this.string_when_empty = string_when_empty;
        this.value_filter = value_filter;
        this.append_key = append_key;
        this.color_code_field = color_code_field;


    }

    get_id(): string {
        const key = `${this.field_type}_${this.fieldname_getter}_${this.label}`;
        if (this.append_key) {
            return `${key}_${this.append_key}`;
        }
        return key;

    }

    get_backend_fieldname(): string[] {
        const fields = [`${this.backend_table}.${this.fieldname_getter}`];
        if (this.color_code_field) {
            fields.push(`${this.backend_table}.${this.color_code_field}`);
        }
        return fields;
    }

    is_sortable(): boolean {
        return SORTING_BACKENDS.includes(this.backend_table);
    }

    is_filterable(): boolean {
        return false;
    }

    set_filter_type_for_formatter(formatter_key, { formatter }): this {
        switch (formatter_key) {
        case "toFixed":
            this.filter_config = {
                formatter,
                min: 0,
                max: 100,
                filter_method_type: FILTER_METHOD_TYPES.NUMBER_RANGE,
            };
            break;


        case "formatDate":
            this.filter_config = {
                formatter,
                filter_method_type: FILTER_METHOD_TYPES.DATE_RANGE,
            };
            break;
        case "formatDateTime":
            this.filter_config = {
                formatter,
                filter_method_type: FILTER_METHOD_TYPES.DATE_RANGE,
            };
            break;
        case "metricPercentage":
            this.filter_config = {
                formatter,
                min: 0,
                max: 1,
                filter_method_type: FILTER_METHOD_TYPES.NUMBER_RANGE,
            };
            break;

        default:

        }
        return this;
    }

    // * set the value from one of the the objects
    set_value(datasource: DataSource): this {
        // always reset the value
        this.value = null;
        this.value = this.value_filter(datasource.layer[this.fieldname_getter], datasource);
        return this;
    }

    // * value as retrieved from the api
    get_value(): any {
        return this.value;
    }

    clone(): this {
        const clonedInstance = Object.create(this);
        // Copy the properties from the current instance to the cloned instance
        Object.assign(clonedInstance, this);
        return clonedInstance;
    }

    // * set the fieldname for setting on the object
    set_fieldname_setter(new_fieldname: string): this {
        this.fieldname_setter = new_fieldname;
        return this;
    }

    // * set the fieldname for setting on the object
    set_fieldname_getter(new_fieldname: string): this {
        this.fieldname_getter = new_fieldname;
        return this;
    }

    // * set the color code field
    set_color_code_field(color_code_field: string | false): this {
        this.color_code_field = color_code_field;
        return this;
    }

    set_color_code(object: any): void {
        this.flag_color = this.color_code_field ? object[this.color_code_field] : "";
        this.flag_class = this.color_code_field ? `text-${object[this.color_code_field]}` : "";
    }

    set_classname(classname: string): void {
        this.classname = classname;
    }

    get_classname(): string {
        return `${this.classname} ${this.flag_class}`;
    }

    // * set the label
    set_label(new_label: string): this {
        // TODO: to be solved in the wizard (every tenant should call NEW)
        const clonedInstance = Object.create(this);
        // Copy the properties from the current instance to the cloned instance
        Object.assign(clonedInstance, this);
        clonedInstance.label = new_label; // Set the new label on the cloned instance

        return clonedInstance;
    }

    // try to get a label for this form option value
    get_form_option_label(options, value): string {
        if (this.fieldname_getter in options) {
            const option = options[this.fieldname_getter].find((option: FormOption) => option.value === value);
            return option ? option.label : value;

        }
        return value;

    }

    // * set a single option
    set_option(option: string, value: any): this {
        (this as any)[option] = value; // Type assertion for dynamic property access
        return this;
    }

    // * set multiple options at once
    set_options(options: { [key: string]: any }): this {
        Object.entries(options).forEach(([option, value]) => {
            this.set_option(option, value);
        });
        return this;
    }

    // * dynamicly add a formatter
    add_formatter(formatter: ((value: string | number | null) => string)): this {
        this.formatters.push(formatter);
        return this;
    }

    // * format the value by looping over all the formatters
    formatted_value(): string {
        return this.formatters.reduce((acc, formatter) => formatter(acc), this.value) || this.string_when_empty;
    }

    // * render react component, can be overwritten by special fields
    display_component(): JSX.Element {
        return <span className={this.get_classname()}>{this.formatted_value()}</span>;
    }

    display_vector(size: string, maxLength: number, truncateLength: number): JSX.Element {
        if (!size) {
            size = "7px";
        }

        let text = capitalizeFirstLetter(this.formatted_value());

        if (maxLength && truncateLength) {
            text = truncateText(text, maxLength, truncateLength);
        }

        return <ColorCodedTextVector flag={this.flag_color} fontSize={size}>{text}</ColorCodedTextVector>;

    }

}

export default Field;


