/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faExchange } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
    Button,
    Col, Container,
    FormGroup, FormText,
    Input,
    Label,
    Modal, ModalBody, ModalFooter, ModalHeader,
    Nav, NavItem, NavLink,
    Row,
    TabContent, TabPane
} from "reactstrap";
import { testTenant } from "../../../actions/Auth/actions";
import { getTenant, listTenants, resetAllTenantConfig, resetAllTenantDefect, resetAllTenantFieldOption, updateTenant, updateTenantState, resetAllTenantFacilityLocation } from "../../../actions/Tenants/actions";
import useConfig from "../../../actions/Tenants/config/configHook";
import { CONFIG_TYPES } from "../../../actions/Tenants/config/constants";
import { toast } from "../../../utils/toast";
import { Loading } from "../../Helper/Loading";
import { defaultCheckLocation } from "./Forms/CheckLocationConfigForm";
import { defaultFruitType } from "./Forms/FruitTypeConfigForm";
import { defaultLayerType } from "./Forms/LayerTypeConfigForm";
import { defaultStage } from "./Forms/OverviewTabConfigForm";
import { defaultPdfReport } from "./Forms/PDFReportConfigForm";
import { defaultRootConfig } from "./Forms/RootConfigForm";
import ListTenantConfig from "./ListTenantConfig";
import ListTenantEmailRecipients from "./ListTenantEmailRecipients";
import ListTenantFormOptions from "./ListTenantFormOptions";
import ListTenantInformation from "./ListTenantInformation";
import ListTenantLegacyFeatures from "./ListTenantLegacyFeatures";
import TenantPageWrappedInConfigProvider from "./TenantPageWrappedInConfigProvider";

// * Wrap this page in the config of the tenant we are editing
function WrappedInConfigProviderTenantEditForm() {
    return <TenantPageWrappedInConfigProvider>
        <TenantEditForm />
    </TenantPageWrappedInConfigProvider>;
}

export default WrappedInConfigProviderTenantEditForm;

const tablist = [
    { name: "Root", key: "root", tab: () => <ListTenantConfig title="Root" type={CONFIG_TYPES.ROOT} defaultConfig={defaultRootConfig} /> }, // eslint-disable-line react/display-name
    { name: "Information", key: "information", tab: () => <ListTenantInformation title="Information" /> }, // eslint-disable-line react/display-name
    { name: "Legacy", key: "legacy-features", tab: () => <ListTenantLegacyFeatures title="Legacy features" /> }, // eslint-disable-line react/display-name
    { name: "Form options", key: "form-options", tab: () => <ListTenantFormOptions title="Form options" /> }, // eslint-disable-line react/display-name
    { name: "Overview Tabs", key: "stages", tab: (props) => <ListTenantConfig title="Overview tabs" type={CONFIG_TYPES.STAGE} defaultConfig={defaultStage} {...props} /> }, // eslint-disable-line react/display-name
    { name: "Layer types", key: "layer-types", tab: (props) => <ListTenantConfig title="Layer types" type={CONFIG_TYPES.LAYER_TYPE} defaultConfig={defaultLayerType} {...props} /> }, // eslint-disable-line react/display-name
    { name: "Check locations", key: "check-locations", tab: () => <ListTenantConfig title="Check locations" type={CONFIG_TYPES.CHECK_LOCATION} defaultConfig={defaultCheckLocation} /> }, // eslint-disable-line react/display-name
    { name: "Fruits", key: "fruit-type", tab: () => <ListTenantConfig title="Fruit type" type={CONFIG_TYPES.FRUIT_TYPE} defaultConfig={defaultFruitType} /> }, // eslint-disable-line react/display-name
    { name: "Reports", key: "pdf-report", tab: () => <ListTenantConfig title="PDF Report" type={CONFIG_TYPES.PDF_REPORT} defaultConfig={defaultPdfReport} /> }, // eslint-disable-line react/display-name
    { name: "Color Code", key: "color-code-schemes", tab: () => <ListTenantConfig title="Color Code Schemes" type={CONFIG_TYPES.COLOR_CODE} /> }, // eslint-disable-line react/display-name
    { name: "Email", key: "email-recipients", tab: () => <ListTenantEmailRecipients title="Email Recipients" /> }, // eslint-disable-line react/display-name
];

function TenantEditForm() {
    const current = useSelector((state) => state.tenants.current);
    const isLoading = useSelector((state) => state.tenants.isLoading);
    const isUpdating = useSelector((state) => state.tenants.isUpdating);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const [importModal, setImportModal] = useState(false);
    const [activeTab, setActiveTab] = useState(tablist[0].key);
    const {
        tenant_name,
        created,
    } = current;
    const { tenant_id } = params;

    useEffect(() => {
        if (tenant_id) {
            dispatch(getTenant(tenant_id));
        }

    }, [tenant_id]);

    const onChange = (e) => {
        dispatch(updateTenantState({ ...current, [e.target.name]: e.target.value }));
    };

    const submit = () => {
        Promise.resolve(
            dispatch(updateTenant(current)),
        ).then(() => {
            dispatch(listTenants());
        });
    };

    const _testTenant = () => dispatch(testTenant(current)).then(() => navigate("/"));

    const toggleTabs = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab.key);
        }
    };

    if (!current || isLoading) {
        return <Loading />;
    }

    const buttons = [
        <Button key={0} color="primary" disabled={isUpdating || isLoading} onClick={() => submit()}>Submit</Button>,
        <Button key={1} color="secondary" className="ms-2 text-nowrap" outline disabled={isUpdating || isLoading} onClick={() => _testTenant()}>Test</Button>,
        <Button key={2} color="secondary" className="ms-2 text-nowrap" outline onClick={() => setImportModal(true)}><FontAwesomeIcon icon={faExchange} /> Import / Export </Button>,
    ];

    return (
        <Container className="py-5">
            <Row className="justify-content-center">
                <Col lg="9">
                    <div className="d-flex justify-content-between pb-2">
                        <div>
                            <h2 className="">Edit tenant {tenant_name}</h2>
                        </div>
                        <div>
                            {buttons}
                            <Button className="btn-close my-1 ms-2 text-nowrap" size="lg" onClick={() => navigate(`/tenants`)} ></Button>
                        </div>
                    </div>
                    <Row className="justify-content-between pb-2">
                        <Col md="12">
                            <FormGroup>
                                <Label for="tenant_name">Tenant name</Label>
                                <Input type="text" name="tenant_name" id="tenant_name" disabled value={tenant_name} onChange={onChange} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="justify-content-between pb-2">
                        <Col md="12">
                            <FormGroup>
                                <Label for="tenant_id">Tenant ID</Label>
                                <Input type="text" name="tenant_id" id="tenant_id" disabled value={tenant_id} onChange={onChange} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="justify-content-between pb-2">
                        <Col md="12">
                            <FormGroup>
                                <Label for="created">Created</Label>
                                <Input type="text" disabled name="created" id="created" value={dayjs(created).format("DD-MMM-YYYY HH:mm")} />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row className="justify-content-center pb-4">
                        <Nav tabs>
                            {tablist.map((tab) => <NavItem key={tab.key} >
                                <NavLink
                                    className={classnames({ active: activeTab === tab.key })}
                                    onClick={() => { toggleTabs(tab); }}>
                                    {tab.name}
                                </NavLink>
                            </NavItem>)}
                        </Nav>
                    </Row>

                    <TabContent activeTab={activeTab}>
                        <TabPane tabId={activeTab}>
                            <Row>
                                <Col sm="12">
                                    {tablist.find((i) => i.key === activeTab).tab()}
                                </Col>
                            </Row>
                        </TabPane>
                    </TabContent>

                    <FormGroup className="pt-4">
                        {buttons}
                    </FormGroup>

                </Col>
            </Row>
            <TenantImportExportModal importModal={importModal} setImportModal={setImportModal} />
        </Container>);
}

const exportData = (data, fileName, type) => {
    // Create a link and download the file
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
};

function TenantImportExportModal({ importModal, setImportModal }) {
    const [importData, setImportData] = useState(false);
    const [selectedFile, setSelectedFile] = useState(false);
    const isLoading = useSelector((state) => state.tenants.isLoading);
    const isUpating = useSelector((state) => state.tenants.isUpdating);
    const tenant = useSelector((state) => state.tenants.current);
    const options_by_field = useSelector((state) => state?.tenants?.options_by_field);
    const defects_by_fruit_type = useSelector((state) => state?.tenants?.defects_by_fruit_type);
    const facility_locations = useSelector((state) => state?.tenants?.locations);
    // const configs = useSelector((state) => state?.tenants?.configs);
    const dispatch = useDispatch();
    const config = useConfig();

    const [fileSelectorValue, setFileSelectorValue] = useState("");
    const fileChangeHandler = (e) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                try {
                    const jsonData = JSON.parse(event.target.result);
                    setImportData(jsonData);
                    setSelectedFile(file);
                } catch (error) {
                    toast.error(`Error parsing JSON file: ${error}`, {
                        autoClose: 1500,
                    });
                }
            };
            setFileSelectorValue(e.target.value);
            reader.readAsText(file);
        }
    };

    const exportOptions = () => {
        const form_options = options_by_field[tenant.tenant_id];
        const defects = defects_by_fruit_type[tenant.tenant_id];
        const { configs } = config;

        const data = {
            tenant,
            form_options: Object.keys(form_options).flatMap((field) => form_options[field]),
            configs,
            defects: Object.keys(defects).flatMap((fruit_type) => defects[fruit_type]),
            facility_locations
        };

        const jsonData = JSON.stringify(data, null, 2);

        exportData(jsonData, `export-${tenant.tenant_name}-${dayjs().format("DD-MMM-YYYY-HHmm")}-all.json`, "application/json;charset=utf-8;");
    };

    const handleImport = () => {
        if (!importData) return;

        const promises = [
            dispatch(updateTenant(importData.tenant)),
            dispatch(resetAllTenantFieldOption(importData.tenant.tenant_id, importData.form_options))
        ];

        if (importData.configs) {
            promises.push(dispatch(resetAllTenantConfig(importData.tenant.tenant_id, importData.configs)));
        }
        if (importData.defects) {
            promises.push(dispatch(resetAllTenantDefect(importData.tenant.tenant_id, importData.defects)));
        }
        if (importData.facility_locations) {
            promises.push(dispatch(resetAllTenantFacilityLocation(importData.tenant.tenant_id, importData.facility_locations)));
        }

        Promise.all(promises).then(() => {
            setImportModal(false);
        });

    };

    return <Modal size="lg" isOpen={importModal} toggle={() => setImportModal(false)} >
        <ModalHeader toggle={() => setImportModal(false)} >Import / Delete all</ModalHeader>
        <ModalBody>
            <p>This will either import or export all tenant settings, including name, power bi settings, fields, form fields, form options and legacy settings. <b>An import will remove all current fields and forms!</b> Use with care.</p>
            <FormGroup>
                <Label for="exampleFile">JSON</Label>
                <Input
                    id="exampleFile"
                    name="file"
                    type="file"
                    value={fileSelectorValue}
                    onChange={fileChangeHandler}
                />

                {selectedFile ? (
                    <div className="py-3">
                        <FormText >
                            <div>Filename: {selectedFile.name}</div>
                            <div>Filetype: {selectedFile.type}</div>
                            <div>Size in bytes: {selectedFile.size}</div>
                            <div>Configs: <b>{importData && importData?.configs?.length}</b></div>
                            <div>Form Options: <b>{importData && importData?.form_options?.length}</b></div>
                            <div>Defects: <b>{importData && importData?.defects?.length}</b></div>
                            <div>Facility locations: <b>{importData && importData?.facility_locations?.length}</b></div>
                        </FormText>
                    </div>

                ) : (<FormText>Upload json file here.</FormText>)}
            </FormGroup>
            {importData && importData.tenant.tenant_id !== tenant.tenant_id && <div className="alert alert-danger">The tenant id of the import file does not match the current tenant id. This is probably a bad idea. <br></br>
                import {importData.tenant.tenant_id} ({importData.tenant.tenant_name}) !== {tenant.tenant_id} ({tenant.tenant_name})  </div>}
            {importData && <div css={css`max-height: 70vh;overflow-y:scroll;`}>
                <pre>
                    {JSON.stringify(importData, null, 2)}
                </pre>
            </div>
            }

        </ModalBody>
        <ModalFooter>
            <Button color="light" onClick={() => setImportModal(false)}>Cancel</Button>
            <Button color="light" className="me-1 mb-1" onClick={() => exportOptions()}>Export</Button>
            <Button color="primary" disabled={isLoading || isUpating || !importData} className="me-1 mb-1" onClick={() => handleImport()}>Import</Button>
        </ModalFooter>
    </Modal>;
}

TenantImportExportModal.propTypes = {
    importModal: PropTypes.bool.isRequired,
    setImportModal: PropTypes.func.isRequired
};
